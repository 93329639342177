import styled from 'styled-components';

type Props = {
  width?: string;
  flex?: string;
  align?: string;
  justify?: string;
  mb?: string;
};

export const Row = styled.div<Props>`
  ${({ theme }) => theme.flex.row}
  flex: ${(props) => props.flex || ''};
  width: ${(props) => props.width || 'auto'};
  align-items: ${(props) => props.align || 'auto'};
  justify-content: ${(props) => props.justify || 'auto'};
  margin-bottom: ${(props) => props.mb || '0'};
`;

export const Col = styled.div<Props>`
  ${({ theme }) => theme.flex.col}
  width: ${(props) => props.width || 'auto'};
  align-items: ${(props) => props.align || 'auto'};
  justify-content: ${(props) => props.justify || 'auto'};
  margin-bottom: ${(props) => props.mb || '0'};
`;

export const UserMain = styled(Row)`
  width: 100%;
  height: 100%;
  max-width: 1920px;
  .fw300 {
    font-weight: 300;
  }

  .clock {
    position: absolute;
    right: 50px;
    bottom: 50px;
    font-size: 2rem;
    font-weight: 600;
    &::before {
      font-family: 'FontAwesome';
      content: '\f017';
      margin-right: 10px;
    }
  }
`;

export const Left = styled(Col)`
  width: 150px;
  height: 100%;
  min-height: 100vh;
  padding: 50px 30px;

  img {
    align-items: flex-start;
    margin-bottom: 85px;
  }
`;
export const Right = styled(Col)`
  flex: 1;
  background-color: #e8eff8;
  padding: 50px;

  img {
    // 군부대 마크
    height: 75px;
    margin-right: 20px;
  }
`;

export const Logout = styled.button`
  font-size: 1rem;
  background-color: #fff;
  padding: 0 20px;
  border-radius: 5px;

  &::after {
    font-family: 'FontAwesome';
    content: '\f0a9';
    margin-left: 10px;
  }
`;

export const Users = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 3vw;
`;

export const User = styled(Col)`
  position: relative;
  .put {
    background-color: #293b5f;
    color: #fff;
  }
`;

export const Status = styled.div`
  width: 2.5vw;
  height: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  background-color: #d9d9d9;
  color: #fff;
  top: -1.25vw;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const Info = styled(Col)`
  width: 100%;
  background-color: #fff;
  align-items: center;
  padding: 2vw;
  border-radius: 10px 10px 0 0;

  p {
    font-size: 1.25vw;
  }

  .name {
    font-size: 1.7vw;
    font-weight: 700;
  }
`;

export const StatusName = styled.div`
  width: 100%;
  padding: 20px;
  color: #191919;
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 1.25vw;
  font-weight: 700;
  background-color: #d9d9d9;
`;
