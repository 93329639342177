import MyPage from '../MyPage';
import { useState, useCallback, useEffect } from 'react';
import {
  apiRoute,
  requestSecureGet,
} from '@libs/api';
import useToken from '@hooks/useToken';
import { MyPageTypes } from '@typedef/components/MyPage/mypage.types';

const MyPageContainer = () => {
  const { getAccessToken } = useToken();
  const [inputs, setInputs] = useState<MyPageTypes>({
    id: '',
    name: '',
    phone: '',
    manager_name: '',
    manager_phone: '',
    manager_phone1: '',
    manager_phone2: '',
    manager_phone3: '',
  });
  const [showModal, setShowModal] = useState<boolean>(false);

  const onChangeInputs = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  }, []);


  // 마이페이지 정보 수정
  const update = useCallback(async () => {}, []);

  // 계정정보 조회
  const getAccountData = useCallback(async () => {
    const { config, data } = await requestSecureGet<MyPageTypes>(
      apiRoute.account.getMy,
      {},
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setInputs(data);
    }
  }, [getAccessToken]);

  useEffect(() => {
    getAccountData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MyPage
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      showModal={showModal}
      setShowModal={setShowModal}
      update={update}
    />
  );
};

export default MyPageContainer;
