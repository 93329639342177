import History from '../History';
import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import useToken from '@hooks/useToken';
import { apiRoute, requestSecureGet } from '@libs/api';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.type';
import { HistoryDataTypes } from '@typedef/components/History/history.data.types';
import { NoColumn } from '@components/Common/CTable/components/IdxColumn';
import moment from 'moment';

const HistoryContainers = () => {
  const { getAccessToken } = useToken();
  const [tableData, setTableData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // 테이블 검색
  const searchTable = useCallback(() => {
    if (inputRef.current) {
      setSearchValue(inputRef.current.value);
    }
  }, []);

  // 테이블 검색내역 삭제
  const clearSearchValue = useCallback(() => {
    const { current } = inputRef;
    if (current) {
      current.value = '';
    }
    setSearchValue(null);
  }, []);

  const columns: TableColumnTypes[] = useMemo(
    () => [
      NoColumn('idx'),
      // {
      //   accessor: 'belong.name',
      //   Header: '소속',
      // },
      {
        Header: '사용자명',
        accessor: 'user_nm',
      },
      // {
      //   accessor: 'class',
      //   Header: '직책',
      // },
      {
        Header: '개인번호(군번)',
        accessor: 'user_sn',
      },
      // {
      //   accessor: 'belong_device.device_info.device.pet_name',
      //   Header: '단말기',
      // },
      {
        Header: '작업',
        accessor: 'operation',
        Cell: ({ value }: any) => (
          <div className={value.includes('입고') ? 'status in' : 'status out'}>
            {value}
          </div>
        ),
      },
      {
        Header: '일시',
        accessor: 'createdAt',
        Cell: ({ value }: any) => (
          <>{moment(value).format('YYYY-MM-DD hh:mm:ss')}</>
        ),
      },
    ],
    [],
  );

  const getTableData = useCallback(async () => {
    const { config, data } = await requestSecureGet<HistoryDataTypes[]>(
      apiRoute.history.getHistories,
      {},
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      const tableData = data.filter(
        (data) =>
          data.operation === '키오스크 출고' ||
          data.operation === '키오스크 입고',
      );
      setTableData(tableData);
    }
  }, [getAccessToken]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  return (
    <History
      columns={columns}
      tableData={tableData}
      inputRef={inputRef}
      searchValue={searchValue}
      searchTable={searchTable}
      clearSearchValue={clearSearchValue}
    />
  );
};

export default HistoryContainers;
