import styled from 'styled-components';

export const CheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    appearance: none;
    cursor: pointer;
    transition: background 0.2s;
  }
  input[type='checkbox']:checked {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0f3460;
    border: 1px solid #0f3460;
  }
  input[type='checkbox']:checked::after {
    font-family: 'FontAwesome';
    content: '\f00c';
    color: #fff;
  }
`;
