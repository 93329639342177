import * as S from './styles/GNBStyle';
import { GnbItemTypes } from '@typedef/components/Common/GNB/gnb.type';

type Props = {
  navList: GnbItemTypes[];
};

const GNB = ({ navList }: Props) => {
  return (
    <S.GNB>
      {navList.map((nav, index) => (
        <S.Nav to={nav.path} key={nav.path}>
          <div className='iconBox'>
            <nav.icon className='icon' />
          </div>
          <div className='tooltip'>{nav.name}</div>
        </S.Nav>
      ))}
    </S.GNB>
  );
};

export default GNB;
