import * as S from './styles/HeaderStyle';
import { NavLink } from 'react-router-dom';
import image from '@assets/image';
import ProfileMenuContainer from './containers/ProfileMenuContainer';

type Props = {
  userName: string;
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const Header = ({ userName, showMenu, setShowMenu }: Props) => {
  return (
    <S.Header>
      <NavLink to=''>
        <img src={image.idmLogo} alt='logo' className='logo' />
      </NavLink>
      <S.Profile>
        <p>
          안녕하세요!&nbsp;&nbsp;
          <strong
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}>
            {userName}
          </strong>
          님
        </p>
        {showMenu && <ProfileMenuContainer setShowMenu={setShowMenu} />}
      </S.Profile>
    </S.Header>
  );
};

export default Header;
