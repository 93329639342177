import * as S from '../styles/HeaderStyle';

type Props = {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  userName: string;
  logout: () => void;
};

const ProfileMenu = ({ setShowMenu, userName, logout }: Props) => {
  return (
    <S.ProfileMenu
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}>
      <S.Menu>
        {/* <NavLink to='/mypage' className='user'>
          마이페이지
        </NavLink> */}
        <button type='button' onClick={logout}>
          로그아웃
        </button>
      </S.Menu>
    </S.ProfileMenu>
  );
};

export default ProfileMenu;
