import Current from '../Current';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { NoColumn } from '@components/Common/CTable/components/IdxColumn';
import useToken from '@hooks/useToken';
import { CurrentDataTypes } from '@typedef/components/Current/current.data.types';
import { apiRoute, requestSecureGet } from '@libs/api';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.type';

const CurrentContainer = () => {
  const { getAccessToken } = useToken();
  const [tableData, setTableData] = useState<CurrentDataTypes[]>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const columns: TableColumnTypes[] = useMemo(
    () => [
      NoColumn('idx'),
      {
        accessor: 'belong.name',
        Header: '소속',
      },
      {
        accessor: 'name',
        Header: '사용자명',
      },
      {
        accessor: 'class',
        Header: '직책',
      },
      {
        accessor: 'serial',
        Header: '개인번호(군번)',
      },
      {
        accessor: 'belong_device.device_info.device.pet_name',
        Header: '단말기',
      },
      {
        accessor: 'belong_device.status.name',
        Header: '상태',
        Cell: ({ value }: any) => {
          return (
            <div className={value === '입고' ? 'status in' : 'status out'}>
              {value === '입고' ? '단말보관중' : '사용중'}
            </div>
          );
        },
      },
      // {
      //   Header: '일시',
      //   Cell: ({ row }: { row: any }) => {
      //     const status = row.original.belong_device.status.name;
      //     const time =
      //       status === '입고'
      //         ? row.original.belong_device.in_date
      //         : row.original.belong_device.out_date;
      //     return <>{dateToFormatDate(new Date(time))}</>;
      //   },
      // },
    ],
    [],
  );

  // 테이블 검색
  const searchTable = useCallback(() => {
    if (inputRef.current) {
      setSearchValue(inputRef.current.value);
    }
  }, []);

  // 테이블 검색내역 삭제
  const clearSearchValue = useCallback(() => {
    const { current } = inputRef;
    if (current) {
      current.value = '';
    }
    setSearchValue(null);
  }, []);

  const getTableData = useCallback(async () => {
    const { config, data } = await requestSecureGet<CurrentDataTypes[]>(
      apiRoute.user.getUsers,
      {},
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400) setTableData(data);
  }, [getAccessToken]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  return (
    <Current
      columns={columns}
      tableData={tableData}
      searchValue={searchValue}
      inputRef={inputRef}
      searchTable={searchTable}
      clearSearchValue={clearSearchValue}
    />
  );
};

export default CurrentContainer;
