import SelectUserDevice from '../components/SelectUserDevice';
import { useState, useEffect, useCallback } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import { ShopDeviceTypes } from '@typedef/components/DeviceManage/shop.device.types';
import useToken from '@hooks/useToken';

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDeviceInputs: React.Dispatch<React.SetStateAction<ShopDeviceTypes | null>>;
};

const SelectUserDeviceContainer = ({
  setShowModal,
  setDeviceInputs,
}: Props) => {
  const { getAccessToken } = useToken();
  const [shopDevices, setShopDevices] = useState<ShopDeviceTypes[] | null>(
    null,
  );
  const [searchDevices, setSearchDevices] = useState<ShopDeviceTypes[] | null>(
    null,
  );
  const [selectedDevice, setSelectedDevice] = useState<ShopDeviceTypes | null>(
    null,
  );

  // 모달 닫기
  const onClickCloseModal = useCallback(
    (e: React.MouseEvent) => {
      const { id } = e.target as HTMLDivElement;
      if (id === 'backGround') {
        setShowModal(false);
      }
    },
    [setShowModal],
  );

  // 일련번호로 단말 검색
  const onChangeSerialNum = useCallback(
    (value: string) => {
      const filter = shopDevices?.filter(
        (device) =>
          device.serial_number &&
          device.serial_number.match(new RegExp(value, 'i')),
      );
      if (filter) {
        setSearchDevices(filter);
      }
    },
    [shopDevices],
  );

  // 단말 선택
  const onClickDevice = useCallback((device: ShopDeviceTypes) => {
    setSelectedDevice(device);
  }, []);

  // 단말 선택 취소
  const onClickCancelDevice = useCallback(() => {
    setSelectedDevice(null);
  }, []);

  // 추가 버튼
  const onClickAddBtn = useCallback(() => {
    setDeviceInputs(selectedDevice);
    setShowModal(false);
  }, [selectedDevice, setDeviceInputs, setShowModal]);

  // 사용자 단말 선택을 위한 shop_device 조회
  const getShopDeviceData = useCallback(async () => {
    const { config, data } = await requestSecureGet<ShopDeviceTypes[]>(
      apiRoute.shopDevice.getShopDevices,
      {},
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      // 사용자 정보가 있는 단말 제외
      const filtered = data.filter((device) => device.users?.idx === null);
      setShopDevices(filtered);
    }
  }, [getAccessToken]);

  useEffect(() => {
    getShopDeviceData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectUserDevice
      onClickCloseModal={onClickCloseModal}
      onChangeSerialNum={onChangeSerialNum}
      devices={searchDevices ? searchDevices : shopDevices}
      onClickDevice={onClickDevice}
      onClickCancelDevice={onClickCancelDevice}
      selectedDevice={selectedDevice}
      onClickAddBtn={onClickAddBtn}
    />
  );
};

export default SelectUserDeviceContainer;
