import * as S from '@styles/ModalStyle';
import { RelatedTypes } from '@typedef/components/BelongManage/related.types';
import { UserTypes } from '@typedef/components/UserManage/user.types';

type Props = {
  onClickCloseModal: (e: React.MouseEvent<HTMLDivElement>) => void;
  belongs: RelatedTypes[] | null;
  onClickBelong: (e: React.MouseEvent<HTMLDivElement>) => void;
  selectedBelong: number | 'all';
  users: UserTypes[] | null;
  onClickUser: (idx: string) => void;
  selectedUsers: string[];
  onClickAddBtn: () => void;
};

const SelectKioskUser = ({
  onClickCloseModal,
  belongs,
  onClickBelong,
  selectedBelong,
  users,
  onClickUser,
  selectedUsers,
  onClickAddBtn,
}: Props) => {
  return (
    <S.ModalBackGround id='backGround' onClick={onClickCloseModal}>
      <S.Modal>
        <S.ModalTitleBox>
          <h3>단말보관함 사용자 추가</h3>
        </S.ModalTitleBox>
        <S.ModalContent>
          <S.Belongs>
            <S.Belong
              id='all'
              onClick={onClickBelong}
              className={selectedBelong === 'all' ? 'activeBelong' : ''}>
              전체
            </S.Belong>
            {belongs &&
              belongs.map((belong) => (
                <S.Belong
                  key={belong.store.idx}
                  id={belong.store.idx}
                  onClick={onClickBelong}
                  className={
                    selectedBelong === belong.store.idx ? 'activeBelong' : ''
                  }>
                  {belong.store?.name}
                </S.Belong>
              ))}
          </S.Belongs>
          <S.Table>
            <S.Tbody>
              {users &&
                users.map((user) => (
                  <S.Tr
                    key={user.serial}
                    onClick={() => onClickUser(user.idx)}
                    className={
                      selectedUsers?.includes(user.idx) ? 'activeUser' : ''
                    }>
                    <strong>{user.name}</strong>
                    <div>{user.serial}</div>
                  </S.Tr>
                ))}
            </S.Tbody>
          </S.Table>
        </S.ModalContent>
        <S.ModalBtns>
          <S.ModalBtn
            type='button'
            disabled={selectedUsers.length === 0}
            onClick={onClickAddBtn}>
            추가&nbsp;{selectedUsers && `(${selectedUsers.length})`}
          </S.ModalBtn>
        </S.ModalBtns>
      </S.Modal>
    </S.ModalBackGround>
  );
};

export default SelectKioskUser;
