import * as S from '../styles/NavigationStyle';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import useUser from '@hooks/useUser';
import NotFound from '@components/Common/NotFound/NotFound';
import JSolRoleNavigation from './JSolRoleNavigation';
import AdminRoleNavigaiton from './AdminRoleNavigaiton';
import HeaderContainer from '@components/Common/Header/containers/HeaderContainer';
import GNBContainer from '@components/Common/GNB/containers/GNBContainer';

const MainNavigation = () => {
  const { getUser } = useUser();
  const role = useMemo(() => getUser().role, [getUser]);
  return (
    <S.Page>
      <HeaderContainer />
      <S.Content>
        <GNBContainer />
        <S.Routes>
          <Routes>
            {role?.includes('JSOL') && (
              <Route path='*' element={<JSolRoleNavigation />} />
            )}
            {role?.includes('PARENT') && (
              <Route path='*' element={<AdminRoleNavigaiton />} />
            )}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </S.Routes>
      </S.Content>
    </S.Page>
  );
};

export default MainNavigation;
