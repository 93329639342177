import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import App from './App';
import './index.css';
import io from 'socket.io-client';
import { getKioskInfo } from './module/CommonFuncs';
import {API_ORIGIN} from "@libs/api";

export const socket = io(API_ORIGIN, {
  path: '/socket', // server socket io 의 path와 동일해야 한다.
  transports: ['websocket'],
  upgrade: true,
  // forceNew: true,
  autoConnect: true,
});

// export const socket = io('http://localhost:11301', {
//   path: '/api/inout/socket',
//   transports: ['websocket'],
//   upgrade: true,
//   // forceNew: true,
//   autoConnect: true,
// });

socket.emit('join', {
  username: getKioskInfo().name,
  roomId: getKioskInfo().uid,
});

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);
