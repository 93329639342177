/**
 * author : 이성복(leeseongbok1214@github.com)
 * version : 1
 */

import moment from 'moment';
import { numberWithCommas } from './DataFormat';

export function closeWindow() {
  window.close();
}

export const serverErrorMessage = () => {
  return alert('서버 에러, 문의바랍니다.');
};

export const getKioskInfo = () => {
  return {
    idx: localStorage.getItem('kiosk_idx'),
    max: localStorage.getItem('kiosk_max'),
    memo: localStorage.getItem('kiosk_memo'),
    name: localStorage.getItem('kiosk_name'),
    uid: localStorage.getItem('kiosk_uid'),
  };
};

export function getLoginInfo() {
  return {
    access_token: localStorage.getItem('access_token'),
    refresh_token: localStorage.getItem('refresh_token'),
    role: localStorage.getItem('role'),
    name: localStorage.getItem('name'),
    shopId: localStorage.getItem('shopId'),
    shopName: localStorage.getItem('shopName'),
  };
}

export const isAgency = () => {
  return isRole('ROLE_AGENCY');
};

export const isStore = () => {
  return isRole('ROLE_STORE');
};

export const isRole = (targetRole) => {
  const role = localStorage.getItem('role');
  if (isNull(role)) return '';
  return role.toUpperCase().startsWith(targetRole);
};

export function isValid(data) {
  return !isNull(data);
}
export function isNull(data) {
  if (Array.isArray(data)) {
    return data.length === 0;
  } else if (data instanceof Object) {
    return Object.keys(data).length === 0;
  }
  return !data;
}

export function getElementById(id) {
  if (!id) throw new Error('id가 없습니다.');
  console.log('getElementById > id :', id);
  return document.getElementById(id);
}

export function getElementValueById(id) {
  if (id) {
    const element = getElementById(id);
    if (element) {
      return element.value;
    }
  }
  return '';
}

export function setElementValueById(id, value) {
  return (getElementById(id).value = value);
}

export function getElementTextById(id) {
  if (id) return getElementById(id).innerText;
  return id;
}

export function setElementTextById(id, value) {
  console.log(getElementById(id));
  if (id) if (value) return (getElementById(id).innerText = value);
  return value;
}

export function setElementSelectOption(id, value, setter) {
  const element = getElementById(id);
  for (let i = 0; i < element.length; i++) {
    if (Number(element[i].value) === Number(value)) {
      element[i].selected = true;
      if (setter) setter(value);
      break;
    }
  }
}

/**
 * 대상 name값에 해당하는 모든 input을 this.checked 상태로 변경한다.
 * @param {boolean} checked 설정 값
 * @param {string} name 대상 name값
 */
export const checkAllByName = (checked, name) => {
  const list = document.querySelectorAll(`input[name=${name}]`);
  for (let i = 0; i < list.length; i++) {
    list[i].checked = checked;
  }
  return list;
};

export function isCheckedById(id) {
  return getElementById(id).checked;
}

export const getCheckBoxValueByName = (name) => {
  const checkBox = document.querySelector(`input[name="${name}"]:checked`);
  if (!checkBox) return '해당하는 name값의 input이 없습니다. name : ' + name;
  return checkBox.value;
};

export function setFocusById(id) {
  if (isValid(id) && id !== '') document.getElementById(id).focus();
}

export function equalNumbers(a, b) {
  return Number(a) === Number(b);
}

export function sum(a, b) {
  return Number(a) + Number(b);
}

export const equalArrays = (a, b) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    a = a.sort();
    b = b.sort();
    return JSON.stringify(a) === JSON.stringify(b);
  }
  return false;
};

/**
 * 문자열의 콤마를 제거한다.
 */
export function replaceComma(pStr) {
  const strCheck = /,/g;
  pStr = pStr.toString().replace(strCheck, '');
  return pStr;
}

/**
 * 문자열의 콤마를 제거후 Number타입으로 변환한다.
 */
export function parseNumberAfterReplaceComma(pStr) {
  /* eslint-disable no-useless-escape */
  pStr = replaceComma(pStr);
  return Number(pStr);
}

/**
 *
 * @param {string|number} num
 * @returns return number type, if NaN return 0
 */
export function parseNumber(num) {
  if (isNaN(num)) return 0;
  else return Number(num);
}

export function parseNumberWithCommas(num) {
  num = parseNumber(num);
  return numberWithCommas(num);
}

function nowMonth() {
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  return moment().year(year).month(month);
}

function nowDate() {
  const date = new Date().getDate();
  return nowMonth().date(date);
}

export function formatMinute(year, month, date, hour, minute) {
  let formatDate = nowDate();
  if (year && month) {
    formatDate = moment().year(year).month(month);
  }
  if (date) {
    formatDate = formatDate.date(date);
  }
  if (hour) {
    formatDate = formatDate.hour(hour);
  }
  if (minute) {
    formatDate = formatDate.minute(minute);
  }
  formatDate = getMomentFormatDateTime(formatDate);
  return formatDate;
}
// js Date타입을 formatDate 함수 호출.
export function dateToFormatDate(time) {
  if (moment.isDate(time)) {
    let year = time.getFullYear();
    if (year.length > 4) year /= 10 * 10 * year.length - 4;
    const month = time.getMonth();
    const date = time.getDate();
    const hour = time.getHours();
    const minute = time.getMinutes();
    const seconds = time.getSeconds();
    const m = moment()
      .year(year)
      .month(month)
      .date(date)
      .hour(hour)
      .minute(minute)
      .seconds(seconds);
    const formatDate = getMomentFormatDateTime(m);
    return formatDate;
  } else {
    return time;
  }
}

export function mainDate(time) {
  if (moment.isDate(time)) {
    let year = time.getFullYear();
    if (year.length > 4) year /= 10 * 10 * year.length - 4;
    const month = time.getMonth();
    const date = time.getDate();
    const hour = time.getHours();
    const minute = time.getMinutes();
    const seconds = time.getSeconds();
    const m = moment()
      .year(year)
      .month(month)
      .date(date)
      .hour(hour)
      .minute(minute)
      .seconds(seconds);
    return m.format('MM/DD HH:mm:ss');
  } else {
    return time;
  }
}

export function formatDate(year, month, date) {
  let formatDate = nowDate();
  if (year) {
    formatDate = moment().year(year);
  }
  formatDate = moment().month(month || month === 0 ? month : 1);
  formatDate = formatDate.date(date ? date : 1);
  formatDate = getMomentFormatDateTime(formatDate);
  return formatDate;
}

export function formatMonth(year, month) {
  let formatDate = nowMonth().date(1);
  if (year && month) {
    formatDate = moment().year(year).month(month).date(1);
  }
  formatDate = getMomentFormatDateTime(formatDate);
  return formatDate;
}

export function getMomentFormatDateTime(moment) {
  return moment.format('yyyy-MM-DD HH:mm:ss');
}

export function getMomentFormatDate(moment) {
  return moment.format('yyyy-MM-DD');
}

export function getMomentFormatDateTimeRRYear(moment) {
  return moment.format('yy-MM-DD HH:mm:ss');
}

export function parseLocalTime(date) {
  return moment(date).utc().format('yyyy-MM-DD HH:mm:ss');
}

export const nextMonth = formatMonth(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
);

export function parseQueryString(search) {
  const queryString = search.substring(1); // ? 제거
  const queries = queryString.split('&').map((data) => data.split('=')); // a=~&b=~ 에서 &를 기준으로 데이터 키,밸류 자르고 =를 기준으로 다시 key value 자르기.
  const obj = {};
  queries.forEach((query) => {
    obj[query[0]] = query[1];
  });
  return obj;
}
