import ChangePassword from '../components/ChangePassword';
import { useState, useCallback } from 'react';
import { apiRoute, requestSecurePatch } from '@libs/api';
import useToken from '@hooks/useToken';

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangePasswordContainer = ({ setShowModal }: Props) => {
  const { getAccessToken } = useToken();
  const [pw, setPw] = useState<string>('');
  const [pwConfirm, setPwConfrim] = useState<string | null>(null);
  const [pwMsg, setPwMsg] = useState<string | null>(null);
  const [pwConfirmMessage, setPwConfirmMessage] = useState<string | null>(null);

  const onClickCloseModal = useCallback(
    (e: React.MouseEvent) => {
      const { id } = e.target as HTMLDivElement;
      if (id === 'backGround') {
        setShowModal(false);
      }
    },
    [setShowModal],
  );

  // 비밀번호
  const onChangePassword = useCallback(
    (value: string) => {
      if (value.length === 0) return;
      const regex = /^(?=.*?\d)(?=.*[a-zA-ZS]).{8,20}/;
      if (regex.test(value)) {
        setPw(value);
        setPwMsg(null);
        if (pwConfirm) {
          if (value !== pwConfirm) {
            setPwConfirmMessage('일치하지 않습니다.');
            setPwConfrim(null);
          }
        }
      } else {
        setPwMsg('8~20자의 영문 대소문자, 숫자만 가능합니다.');
      }
    },
    [pwConfirm],
  );

  // 비밀번호 확인
  const onChangePasswordConfirm = useCallback(
    (value: string) => {
      if (value.length === 0 || pw.length === 0) return;
      if (value === pw) {
        setPwConfirmMessage(null);
        setPwConfrim(value);
      } else {
        setPwConfirmMessage('일치하지 않습니다.');
      }
    },
    [pw],
  );

  // 비밀번호 변경 저장
  const save = useCallback(async () => {
    const { config } = await requestSecurePatch(
      apiRoute.account.patchPassword,
      {},
      { password: pwConfirm },
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 수정이 완료되었습니다.');
      setShowModal(false);
    }
  }, [getAccessToken, pwConfirm, setShowModal]);

  return (
    <ChangePassword
      onClickCloseModal={onClickCloseModal}
      onChangePassword={onChangePassword}
      pwMsg={pwMsg}
      onChangePasswordConfirm={onChangePasswordConfirm}
      pwConfirmMessage={pwConfirmMessage}
      pwConfirm={pwConfirm}
      save={save}
    />
  );
};

export default ChangePasswordContainer;
