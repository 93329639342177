import styled from 'styled-components';

export const Presenter = styled.div`
  min-height: 100vh;
  ${({ theme }) => theme.flex.col}
  padding: 30px 40px;
`;

export const TitleBox = styled.div`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;
  .row {
    ${({ theme }) => theme.flex.row}
  }

  .active {
    background-color: #0f3460;
    color: #fff;
    font-weight: 600;

    &::after {
      font-family: 'FontAwesome';
      content: '\f055';
      margin-left: 10px;
      font-weight: 300;
    }
  }
`;

export const Btns = styled.div`
  ${({ theme }) => theme.flex.row}
`;

export const Btn = styled.button`
  min-width: 45px;
  line-height: 40px;
  padding: 0 15px;
  margin-left: 10px;
  background-color: #ebedf0;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 500;
`;

export const FilterBox = styled.div`
  ${({ theme }) => theme.flex.col}
  background-color: #F5F7FA;
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;

  .filtered {
    margin-top: 10px;
  }

  .none {
    display: none;
  }
`;

export const SearchValue = styled.div`
  ${({ theme }) => theme.flex.row}
  width: fit-content;
  align-items: center;
  padding: 5px 10px;
  background-color: #0f3460;
  color: #fff;
  border-radius: 5px;
  font-size: 0.8rem;
  button {
    margin-left: 10px;
    color: #fff;
  }
`;

export const Detail = styled.div`
  width: 500px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
`;
