import { Navigate, Route, Routes } from 'react-router-dom';
import UserMainContainer from '@components/UserMain/containers/UserMainContainer';

const UserRoleNavigation = () => {
  return (
    <Routes>
      <Route path='' element={<UserMainContainer />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default UserRoleNavigation;
