import * as S from './styles/AdminMainStyle';
import Image from '@assets/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import AdminMainChartContainer from './containers/AdminMainChartContainer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ShopDeviceTypes } from '@typedef/components/DeviceManage/shop.device.types';
import { AdminMainKioskTypes } from '@typedef/components/AdminMain/admin.main.kiosk.types';
import { NavLink } from 'react-router-dom';

type Props = {
  sliderRef: React.MutableRefObject<null>;
  onClickSlickPrev: (ref: any) => () => any;
  onClickSlickNext: (ref: any) => () => any;
  kioskData: AdminMainKioskTypes[] | null;
  putDevices: ShopDeviceTypes[] | null;
  releaseDevices: ShopDeviceTypes[] | null;
};

const AdminMain = ({
  sliderRef,
  onClickSlickPrev,
  onClickSlickNext,
  kioskData,
  putDevices,
  releaseDevices,
}: Props) => {
  return (
    <S.Main>
      <S.Left>
        <S.Kiosks>
          <S.Title>
            <h3>내 단말보관함</h3>
            {kioskData ? (
              kioskData.length > 5 && (
                <S.Row>
                  <button
                    type='button'
                    className='arrow'
                    onClick={onClickSlickPrev(sliderRef)}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </button>
                  <button
                    type='button'
                    className='arrow'
                    onClick={onClickSlickNext(sliderRef)}>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                </S.Row>
              )
            ) : (
              <NavLink to='kiosk-manage'>단말보관함 추가</NavLink>
            )}
          </S.Title>
          {kioskData &&
            (kioskData.length >= 5 ? (
              <div id='slider-wrapper'>
                <Slider
                  infinite={false}
                  slidesToShow={5}
                  slidesToScroll={5}
                  ref={sliderRef}>
                  {kioskData.map((kiosk) => (
                    <S.Kiosk key={kiosk.idx}>
                      <S.Row>
                        <div className='index' />
                        <div className='folderBack' />
                      </S.Row>
                      <S.KioskContent>
                        <h3>{kiosk.name}</h3>
                        <p>
                          {kiosk.user_put}
                          &nbsp;/&nbsp; {kiosk.user}
                        </p>
                        <div className='bar'>
                          <S.BarValue
                            width={(kiosk.user_put / kiosk.user) * 100}
                          />
                        </div>
                      </S.KioskContent>
                    </S.Kiosk>
                  ))}
                </Slider>
              </div>
            ) : (
              <div className='isNotFive'>
                {kioskData.map((kiosk) => (
                  <S.Kiosk key={kiosk.idx} className='kiosk'>
                    <S.Row>
                      <div className='index' />
                      <div className='folderBack' />
                    </S.Row>
                    <S.KioskContent>
                      <h3>{kiosk.name}</h3>
                      <p>
                        {kiosk.user_put}
                        &nbsp;/&nbsp; {kiosk.user}
                      </p>
                      <div className='bar'>
                        <S.BarValue
                          width={(kiosk.user_put / kiosk.user) * 100}
                        />
                      </div>
                    </S.KioskContent>
                  </S.Kiosk>
                ))}
              </div>
            ))}
        </S.Kiosks>
        <S.ChartBox>
          <S.Title>
            <h3>주간 입고 차트</h3>
          </S.Title>
          <S.Chart>
            <AdminMainChartContainer />
          </S.Chart>
        </S.ChartBox>
        <S.Row flex='0.5' mt='30px'>
          <S.Split>
            <S.Title>
              <h3>실시간 현황</h3>
            </S.Title>
            <S.Row flex='1'>
              <S.Summary>
                <S.Icon>&#128582;</S.Icon>
                <S.SummaryContent>
                  <h3>보관중인 단말</h3>
                  <h2>{putDevices?.length}</h2>
                </S.SummaryContent>
              </S.Summary>
              <S.Summary bgc='#FFF1CD'>
                <S.Icon>&#128581;</S.Icon>
                <S.SummaryContent>
                  <h3>사용중인 단말</h3>
                  <h2>{releaseDevices?.length}</h2>
                </S.SummaryContent>
              </S.Summary>
            </S.Row>
          </S.Split>
          <S.Split>
            {/* <S.Title>
              <h3></h3>
            </S.Title>
            <S.SplitContent></S.SplitContent> */}
          </S.Split>
        </S.Row>
      </S.Left>
      <S.Right>
        <S.ImgBox>
          <img src={Image.mainIllustration} alt='일러스트' />
        </S.ImgBox>
        <S.Col flex='1'>
          <S.Title>
            <h3>자주 찾는 질문</h3>
          </S.Title>
          <S.FaQ>
            <h4>사용자에 단말을 추가하는 방법은?</h4>
            <p>
              단말관리에서 단말 등록 후 사용자 관리에서 등록한 단말을 선택해
              주세요.
            </p>
          </S.FaQ>
          <S.FaQ>
            <h4>단말관리에 원하는 기종과 색상이 없어요.</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
            </p>
          </S.FaQ>
          <S.FaQ>
            <h4>입출고현황과 입출고이력은 어떻게 다른가요?</h4>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form
            </p>
          </S.FaQ>
        </S.Col>
      </S.Right>
    </S.Main>
  );
};

export default AdminMain;
