import styled from 'styled-components';

type Props = {
  width?: string;
  flex?: string;
  align?: string;
  justify?: string;
};

export const Row = styled.div<Props>`
  ${({ theme }) => theme.flex.row}
  width: ${(props) => props.width || ''};
  align-items: ${(props) => props.align || ''};
  justify-content: ${(props) => props.justify || ''};
  flex: ${(props) => props.flex || ''};
`;

export const Col = styled.div<Props>`
  ${({ theme }) => theme.flex.col}
  width: ${(props) => props.width || ''};
  align-items: ${(props) => props.align || ''};
  justify-content: ${(props) => props.justify || ''};
  flex: ${(props) => props.flex || ''};
  position: relative;
`;

export const Page = styled(Col)`
  min-height: calc(100vh - 60px);
  padding: 30px 60px;

  .lowBtn {
    font-size: 1rem;
    text-decoration: underline;
    margin-left: 10px;
  }

  .selfStart {
    align-self: flex-start;
  }
`;

export const TitleBox = styled(Row)`
  align-items: center;
  justify-content: space-between;

  .active {
    background-color: #0f3460;
    color: #fff;
    font-weight: 600;

    &::after {
      font-family: 'FontAwesome';
      content: '\f058';
    }
  }
`;

export const Btn = styled.button`
  min-width: 45px;
  line-height: 40px;
  padding: 0 15px;
  margin-left: 10px;
  background-color: #ebedf0;
  color: #0f3460;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 500;

  &::after {
    font-family: 'FontAwesome';
    content: '\f057';
    margin-left: 10px;
    font-weight: 300;
  }
`;

export const Form = styled(Row)`
  padding: 30px 0 10px;
  border-bottom: 1px solid #eee;

  h3 {
    width: 300px;
  }

  &:last-child {
    border-bottom: none;
  }

  .subtitle {
    font-size: 0.9rem;
    font-weight: 300;
    margin-top: 10px;
  }
`;

export const InputBox = styled(Row)`
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  p {
    min-width: 200px;
  }
  span {
    margin-left: 3px;
    color: #4575f6;
  }
  .phone {
    width: 110px;
    text-align: center;
    margin-right: 10px;
  }
  .msg {
    font-size: 0.9rem;
    font-weight: 500;
    color: red;
    margin-left: 15px;
    &::before {
      font-family: 'FontAwesome';
      content: '\f06a';
      margin-right: 10px;
    }
  }
  .cup {
    cursor: pointer;
  }

  .select {
    width: 350px;
    cursor: pointer;

    .css-1s2u09g-control {
      cursor: pointer;
    }
    .css-1pahdxg-control {
      border-color: #4575f6;
    }
    .css-319lph-ValueContainer,
    .css-qc6sy-singleValue {
      font-size: 0.9rem;
      font-weight: 300;
    }

    // disabled
    .css-1insrsq-control {
      background-color: #fff;
      border: 1px solid #ccc;
    }
  }
`;

export const Input = styled.input`
  ${({ theme }) => theme.common.input}
  width: 350px;
  border: 1px solid #ccc;
  &:disabled {
    background-color: #fff;
  }
`;

export const AddBtn = styled.button`
  ${({ theme }) => theme.common.input}
  margin-left: 10px;
  padding: 0 15px;
  background-color: #ebedf0;
  font-weight: 500;
`;

export const SelectedUsers = styled(Row)`
  width: 600px;
  flex-wrap: wrap;
  gap: 1vw;
  border-radius: 5px;
`;

export const SelectedUser = styled(Row)`
  height: 40px;
  background-color: #ebedf0;
  padding: 10px;
  border-radius: 5px;
  font-weight: 300;
  strong {
    margin-right: 10px;
  }
`;

export const EditUser = styled.button`
  height: 40px;
  outline: 2px solid #ebedf0;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 300;
`;

// 마이페이지 > 보안정보
export const MyPageRow = styled(Row)`
  padding: 30px 0;
  border-bottom: 1px solid #eee;

  h3 {
    width: 300px;
  }

  button {
    font-size: 1rem;
    text-decoration: underline;
  }
`;
