import { useCallback } from 'react';
import { KioskTypes } from '@typedef/store/use.kiosk.types';

const KIOSK_KEY = '@kiosk' as const;
export default function useKiosk() {
  const getKiosk = useCallback(() => {
    return JSON.parse(localStorage.getItem(KIOSK_KEY) || '{}') as KioskTypes;
  }, []);

  const setKiosk = useCallback((kiosk: KioskTypes) => {
    localStorage.setItem(KIOSK_KEY, JSON.stringify(kiosk));
  }, []);

  const clearKiosk = useCallback(() => {
    localStorage.removeItem(KIOSK_KEY);
  }, []);

  return { getKiosk, setKiosk, clearKiosk };
}
