import BelongInfo from "../components/BelongInfo";
import { useState, useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from "@libs/api";
import useToken from "@hooks/useToken";
import { BelongInputsDataTypes } from "@typedef/components/BelongManage/components/belongInputs.data.types";

const BelongInfoContainer = () => {
  const { getAccessToken } = useToken();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [type, setType] = useState<string | null>(null);
  const [inputs, setInputs] = useState<BelongInputsDataTypes>({
    idx: "",
    manager_id: "",
    manager_name: "",
    manager_phone: "",
    manager_phone1: "010",
    manager_phone2: "",
    manager_phone3: "",
    name: "",
    phone: "",
  });
  const [idMessage, setIdMessage] = useState<string | null>(null);
  const [pwMessage, setPwMessage] = useState<string | null>(null);
  const [pwConfirmMessage, setPwConfirmMessage] = useState<string | null>(null);

  // onChange(input)
  const onChangeInputs = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  }, []);

  // 아이디
  const regexId = useCallback(
    async (id: string) => {
      if (id.length === 0) return;
      // 아이디 정규식(4~16 영문, 숫자만 가능)
      const regex = /^[A-za-z0-9]{4,16}$/;
      if (!regex.test(id)) {
        setIdMessage("4~16자의 영문 대소문자, 숫자만 가능합니다.");
        return;
      }
      // 아이디 중복 확인
      const { config } = await requestSecureGet(
        apiRoute.account.getValid + id,
        {},
        getAccessToken()!
      );
      if (config.status >= 200 && config.status < 400) {
        setIdMessage(null);
        inputs.manager_id = id;
      } else {
        setIdMessage("이미 사용 중인 아이디입니다.");
      }
    },
    [getAccessToken, inputs]
  );

  // 비밀번호
  const regexPassword = useCallback(
    (pw: string) => {
      if (pw.length === 0) return;
      const regex = /^(?=.*?\d)(?=.*[a-zA-ZS]).{8,20}/;
      if (regex.test(pw)) {
        inputs.manager_pw = pw;
        setPwMessage(null);
        if (inputs.manager_pw_confirm) {
          if (pw !== inputs.manager_pw_confirm) {
            setPwConfirmMessage("일치하지 않습니다.");
            inputs.manager_pw_confirm = "";
          }
        }
      } else {
        inputs.manager_pw = "";
        setPwMessage("8~20자의 영문 대소문자, 숫자만 가능합니다.");
      }
      setInputs(inputs);
    },
    [inputs]
  );

  // 비밀번호 확인
  const samePassword = useCallback(
    (pw: string) => {
      if (pw.length === 0) return;
      if (inputs.manager_pw === pw) {
        inputs.manager_pw_confirm = pw;
        setPwConfirmMessage(null);
      } else {
        inputs.manager_pw_confirm = "";
        setPwConfirmMessage("일치하지 않습니다.");
      }
      setInputs(inputs);
    },
    [inputs]
  );

  const allInputs = useCallback(() => {
    let message = "";
    if (!!!inputs.manager_id) message = "아이디를";
    else if (type === "insert" && !!!inputs.manager_pw) message = "비밀번호를";
    else if (type === "insert" && !!!inputs.manager_pw_confirm)
      message = "비밀번호 확인을";
    else if (!!!inputs.name) message = "부서명을";
    else if (!!!inputs.phone) message = "대표전화를";
    else if (!!!inputs.manager_name) message = "관리자 이름을";
    else if (
      !!!inputs.manager_phone1 ||
      !!!inputs.manager_phone2 ||
      !!!inputs.manager_phone3
    )
      message = "관리자 휴대전화를";
    return message;
  }, [inputs, type]);

  // 부서 추가
  const insert = useCallback(async () => {
    const message = allInputs();
    if (!!message) {
      alert(`${message} 입력해 주세요.`);
      return;
    }

    const postData = {
      ...inputs,
      idx: null,
      manager_pw: inputs.manager_pw_confirm,
      manager_phone:
        inputs.manager_phone1 + inputs.manager_phone2 + inputs.manager_phone3,
      role: "BELONG",
      belong: true,
    };

    const { config } = await requestSecurePost<BelongInputsDataTypes>(
      apiRoute.belong.postBelong,
      {},
      postData,
      getAccessToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 등록이 완료되었습니다.");
      navigate("/belong-manage");
    }
  }, [allInputs, getAccessToken, inputs, navigate]);

  // 부서 수정
  const update = useCallback(async () => {
    const message = allInputs();
    if (!!message) {
      alert(`${message} 입력해 주세요.`);
      return;
    }

    const patchData = {
      ...inputs,
      manager_phone:
        inputs.manager_phone1 + inputs.manager_phone2 + inputs.manager_phone3,
      shop_idx: inputs.idx,
    };

    const { config } = await requestSecurePatch<BelongInputsDataTypes>(
      apiRoute.belong.patchBelong,
      {},
      patchData,
      getAccessToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 수정이 완료되었습니다.");
      navigate("/belong-manage");
    }
  }, [allInputs, getAccessToken, inputs, navigate]);

  const getBelongData = useCallback(
    async (idx: string) => {
      const { config, data } = await requestSecureGet<BelongInputsDataTypes>(
        apiRoute.belong.getBelong + idx,
        {},
        getAccessToken()!
      );
      if (config.status >= 200 && config.status < 400)
        setInputs({
          ...data,
          manager_phone1: data.manager_phone.substring(0, 3),
          manager_phone2: data.manager_phone.substring(3, 7),
          manager_phone3: data.manager_phone.substring(7, 11),
        });
    },
    [getAccessToken]
  );

  const getQuries = useCallback(() => {
    const type = searchParams.get("type");
    const belongId = searchParams.get("belongId");
    if (type) {
      setType(type);
    }
    if (belongId) {
      getBelongData(belongId);
    }
  }, [getBelongData, searchParams]);

  useEffect(() => {
    getQuries();
  }, [getQuries]);

  console.log("inputs :", inputs);

  return (
    <BelongInfo
      type={type}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      regexId={regexId}
      idMessage={idMessage}
      regexPassword={regexPassword}
      pwMessage={pwMessage}
      samePassword={samePassword}
      pwConfirmMessage={pwConfirmMessage}
      insert={insert}
      update={update}
    />
  );
};

export default BelongInfoContainer;
