import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const GNB = styled.div`
  height: 100%;
  ${({ theme }) => theme.flex.col}
`;

export const Nav = styled(NavLink)`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  margin-top: 20px;
  padding: 0 15px;
  color: #aaa;

  .iconBox {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .icon {
    font-size: 1.3rem;
  }

  .tooltip {
    background-color: #0f3460;
    color: #fff;
    padding: 10px 15px;
    position: absolute;
    left: 100px;
    z-index: 1;
    font-size: 0.9rem;
    font-weight: 00;
    letter-spacing: 0.3px;
    border-radius: 5px;
    transform: scale(0);
    opacity: 0;
    transition: 0.15s ease;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      margin-top: -7px;
      margin-left: -7px;
      border: 7px solid transparent;
      border-right-color: #0f3460;
      border-left: 0;
    }
  }

  // active
  &[aria-current] {
    .iconBox {
      background-color: #edf3f9;
    }
    .icon {
      color: #0f3460;
    }
  }

  &:hover {
    .iconBox {
      background-color: #0f3460;
      color: #fff;
    }
    .tooltip {
      transform: scale(1);
      opacity: 1;
      /* transition: visibility 0.7s linear 0s, opacity 500ms; */
    }
    &[aria-current] {
      .icon {
        color: #fff;
      }
    }
  }

  @keyframes scale {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`;
