import * as S from '@styles/PresenterStyle';
import TableContainer from '@components/Common/CTable/containers/TableContainer';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.type';
import { RefObject } from 'react';
import CSearchContainer from '@components/Common/CSearch/containers/CSearchContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
  columns: TableColumnTypes[];
  tableData: any | null;
  inputRef: RefObject<HTMLInputElement>;
  searchValue: string | null;
  searchTable: () => void;
  clearSearchValue: () => void;
};

const History = ({
  columns,
  tableData,
  inputRef,
  searchValue,
  searchTable,
  clearSearchValue,
}: Props) => {
  return (
    <S.Presenter>
      <S.TitleBox>
        <h2>입출고이력</h2>
      </S.TitleBox>
      <S.FilterBox>
        <CSearchContainer func={searchTable} inputRef={inputRef} />
        <div className={searchValue ? 'filtered' : 'none'}>
          <S.SearchValue>
            <p>{searchValue}</p>
            <button type='button' onClick={clearSearchValue}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </S.SearchValue>
        </div>
      </S.FilterBox>
      <TableContainer
        columns={columns}
        tableData={tableData}
        searchValue={searchValue}
      />
    </S.Presenter>
  );
};

export default History;
