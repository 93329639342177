import axios from "axios";

// const dev = "https://m-call.kro.kr/test";
const dev = "https://api-idm.j-sol.co.kr/api/inout";
const prod = "https://api-idm.j-sol.co.kr/api/inout";

export const API_ORIGIN = process.env.NODE_ENV === "development" ? dev : prod;
axios.defaults.baseURL = `${API_ORIGIN}`;

axios.interceptors.request.use((req) => {
  if (process.env.NODE_ENV === "development") {
    console.log(req);
  }

  return req;
});

axios.interceptors.response.use((res) => {
  if (process.env.NODE_ENV === "development") {
    console.log(res);
  }
  return res;
});

export const apiRoute = {
  login: {
    postAdmin: "/login",
    postKiosk: "/kiosk/login",
  },
  account: {
    getMy: "/account/my",
    getValid: "/account/valid/",
    patchPassword: "/account",
  },
  admin: {
    getAdmin: "/shop/",
    getAdmins: "/shop",
    postAdmin: "/shop",
    patchAdmin: "/shop",
    deleteAdmin: "/shop/",
  },
  belong: {
    getBelong: "/shop/",
    getBelongs: "/shop/related",
    postBelong: "/shop",
    patchBelong: "/shop",
    deleteBelong: "/shop/",
  },
  kiosk: {
    getKiosk: "/kiosk/",
    getKiosks: "/kiosk",
    postKiosk: "/kiosk",
    patchtKiosk: "/kiosk",
    deleteKiosk: "/kiosk/",
  },
  device: {
    getDevices: "/device",
    getColorByModelName: "/device/info?model_name=",
  },
  shopDevice: {
    getShopDevice: "/shop/device/",
    getShopDevices: "/shop/device",
    postShopDevice: "/shop/device",
    patchShopDevice: "/shop/device",
    deleteShopDevice: "/shop/device/",
  },
  user: {
    getUser: "/user/",
    getUsers: "/user",
    getKioskUsers: "/user?uid=",
    postUser: "/user",
    patchUser: "/user",
    patchUserKioskIdx: "/user/kiosks",
    deleteUser: "/user/",
  },
  history: {
    getHistories: "/history",
  },
  current: {},
};

export type BasicApiResponse<T> = {
  data: T;
  config: {
    status: number;
  };
};

export type BasicListApiResponse<T> = {
  data: T;
  meta: {
    page: number;
    take: number;
    itemCount: number;
    pageCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
  };
};

export function requestGet<T>(
  url: string,
  header: object
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureGet<T>(
  url: string,
  header: object,
  token: string
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestDelete<T>(
  url: string,
  header: object
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        "Content-Type": "application/json",
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureDelete<T>(
  url: string,
  header: object,
  token: string
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePost<T>(
  url: string,
  header: object,
  body: object,
  token: string
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestPost<T>(
  url: string,
  header: object,
  body: object
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        "Content-Type": "application/json",
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePatch<T>(
  url: string,
  header: object,
  body: object,
  token: string
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestMultipart<T>(
  url: string,
  header: object,
  body: FormData,
  token: string
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}
