import AdminInfo from '../components/AdminInfo';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api';
import { AdminInfoDataTypes } from '@typedef/components/AdminManage/components/adminInfo.data.types';
import useToken from '@hooks/useToken';

const AdminInfoContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();
  const [searchParams] = useSearchParams();
  const [type, setType] = useState<string | null>(null);
  const [inputs, setInputs] = useState<AdminInfoDataTypes>({
    manager_id: '',
    manager_name: '',
    manager_phone: '',
    manager_phone1: '010',
    manager_phone2: '',
    manager_phone3: '',
    manager_pw: '',
    name: '',
    phone: '',
  });
  const [idMessage, setIdMessage] = useState<string | null>(null);
  const [pw, setPw] = useState<string | null>(null);
  const [pwMessage, setPwMessage] = useState<string | null>(null);
  const [pwConfirmMessage, setPwConfirmMessage] = useState<string | null>(null);
  const [alerts, setAlerts] = useState<string | null>(null);

  // 아이디
  const regexId = useCallback(
    async (id: string) => {
      if (id.length === 0) return;
      // 아이디 정규식(4~16 영문, 숫자만 가능)
      const regex = /^[A-za-z0-9]{4,16}$/;
      if (!regex.test(id)) {
        setIdMessage('4~16자의 영문 대소문자, 숫자만 가능합니다.');
        return;
      }
      // 아이디 중복 확인
      const { config } = await requestSecureGet(
        apiRoute.account.getValid + id,
        {},
        getAccessToken()!,
      );
      if (config.status >= 200 && config.status < 400) {
        setIdMessage(null);
        inputs.manager_id = id;
      } else {
        setIdMessage('이미 사용 중인 아이디입니다.');
        inputs.manager_id = '';
      }
      setInputs(inputs);
    },
    [getAccessToken, inputs],
  );

  // 비밀번호
  const regexPassword = useCallback((pw: string) => {
    if (pw.length === 0) return;
    if (pw) {
      const regex = /^(?=.*?\d)(?=.*[a-zA-ZS]).{8,20}/;
      if (regex.test(pw)) {
        setPwMessage(null);
      } else {
        setPwMessage('영문, 숫자를 사용하여 8~20자리 사이로 입력해주세요.');
      }
    }
  }, []);

  console.log('inputs :', inputs);

  // 비밀번호 확인
  const samePassword = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      if (value) {
        if (inputs.manager_pw === value) {
          setPwConfirmMessage(null);
          setPw(value);
        } else {
          setPwConfirmMessage('일치하지 않습니다.');
        }
      }
    },
    [inputs.manager_pw],
  );

  const onChangeInputs = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { name, value } = e.currentTarget;
      setInputs((inputs) => ({
        ...inputs,
        [name]: value,
      }));
      if (inputs.manager_pw) regexPassword(inputs.manager_pw);
    },
    [inputs.manager_pw, regexPassword],
  );

  // 입력 여부 확인
  const allInputs = useCallback(() => {
    if (!!!inputs.manager_id) setAlerts('아이디를');
    else if (!!!pw) setAlerts('올바른 비밀번호를');
    else if (!!!inputs.manager_name) setAlerts('관리자 이름을');
    else if (
      !!!inputs.manager_phone1 ||
      !!!inputs.manager_phone2 ||
      !!!inputs.manager_phone3
    )
      setAlerts('관리자 휴대전화를');
    else if (!!!inputs.name) setAlerts('소속명을');
    else if (!!!inputs.phone) setAlerts('대표전화를');
    else setAlerts(null);
  }, [inputs, pw]);

  // 관리자 등록
  const insert = useCallback(async () => {
    allInputs();
    if (alerts) {
      return alert(`${alerts} 입력해 주세요.`);
    }

    const postData = {
      ...inputs,
      manager_pw: pw,
      manager_phone:
        inputs.manager_phone1 + inputs.manager_phone2 + inputs?.manager_phone3,
      role: 'PARENT',
    };

    const { config } = await requestSecurePost<AdminInfoDataTypes>(
      apiRoute.admin.postAdmin,
      {},
      postData,
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 등록이 완료되었습니다.');
      navigate('/admin-manage');
    }
  }, [alerts, allInputs, getAccessToken, inputs, navigate, pw]);

  // 관리자 정보 수정
  const update = useCallback(async () => {
    const patchData = {
      ...inputs,
      manager_phone:
        inputs.manager_phone1 + inputs.manager_phone2 + inputs?.manager_phone3,
      shop_idx: inputs.idx,
    };
    const { config } = await requestSecurePatch<AdminInfoDataTypes>(
      apiRoute.admin.patchAdmin,
      {},
      patchData,
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 수정이 완료되었습니다.');
      navigate('/admin-manage');
    }
  }, [getAccessToken, inputs, navigate]);

  // 데이터 가져오는 함수
  const getAdminData = useCallback(
    async (idx: string) => {
      const { config, data } = await requestSecureGet<AdminInfoDataTypes>(
        apiRoute.admin.getAdmin + idx,
        {},
        getAccessToken()!,
      );
      if (config.status >= 200 && config.status < 400)
        setInputs({
          ...data,
          manager_phone1: data.manager_phone.substring(0, 3),
          manager_phone2: data.manager_phone.substring(3, 7),
          manager_phone3: data.manager_phone.substring(7, 11),
        });
    },
    [getAccessToken],
  );

  // 타입, 아이디 가져오기
  const getQuries = useCallback(() => {
    const type = searchParams.get('type');
    const adminId = searchParams.get('adminId');
    if (type) {
      setType(type);
    }
    if (adminId) {
      getAdminData(adminId);
    }
  }, [getAdminData, searchParams]);

  useEffect(() => {
    getQuries();
  }, [getQuries]);

  return (
    <AdminInfo
      type={type}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      regexId={regexId}
      idMessage={idMessage}
      pwMessage={pwMessage}
      pwConfirmMessage={pwConfirmMessage}
      samePassword={samePassword}
      insert={insert}
      update={update}
    />
  );
};

export default AdminInfoContainer;
