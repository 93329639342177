import UserInfo from "../components/UserInfo";
import { useState, useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from "@libs/api";
import useToken from "@hooks/useToken";
import { UserInputsTypes } from "@typedef/components/UserManage/components/user.inputs.types";
import { RelatedTypes } from "@typedef/components/BelongManage/related.types";
import { SingleValue } from "react-select";
import { ShopDeviceTypes } from "@typedef/components/DeviceManage/shop.device.types";
import { UserTypes } from "@typedef/components/UserManage/user.types";

const UserInfoContainer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getAccessToken } = useToken();
  const [type, setType] = useState<string | null>(null);
  const [inputs, setInputs] = useState<UserInputsTypes>({
    idx: "",
    belong_idx: "",
    class: "",
    name: "",
    phone: "",
    serial: "",
    phone1: "010",
    phone2: "",
    phone3: "",
  });
  const [deviceInputs, setDeviceInputs] = useState<ShopDeviceTypes | null>(
    null
  );
  const [belongOptions, setBelongOptions] = useState<RelatedTypes[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  // onChange(input)
  const onChangeInputs = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  }, []);

  // 부서 선택 함수
  const onClickBelongName = useCallback(
    (option: SingleValue<RelatedTypes> | null) => {
      if (option) {
        inputs.belong_idx = option.store.idx;
      } else {
        inputs.belong_idx = "";
      }
      setInputs(inputs);
    },
    [inputs]
  );

  // 입력 여부 확인
  const allInputs = useCallback(() => {
    let message = "";
    if (!!!inputs.name) message = "이름을";
    else if (!!!inputs.serial) message = "개인번호(군번)를";
    else if (!!!inputs.phone1 || !!!inputs.phone2 || !!!inputs.phone3)
      message = "휴대전화를";
    else if (!!!inputs.class) message = "직급(계급)을";
    return message;
  }, [inputs]);

  // 사용자 등록
  const insert = useCallback(async () => {
    const message = allInputs();
    if (!!message) {
      alert(`${message} 입력해 주세요.`);
      return;
    }

    const postData = {
      ...inputs,
      idx: null,
      manager_pw: "",
      phone: inputs.phone1 + inputs.phone2 + inputs.phone3,
      belong_device_idx: deviceInputs?.idx,
    };

    const { config } = await requestSecurePost<UserInputsTypes>(
      apiRoute.user.postUser,
      {},
      { users: [postData] },
      getAccessToken()!
    );

    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 등록이 완료되었습니다.");
      navigate("/user-manage");
    }
  }, [allInputs, deviceInputs, getAccessToken, inputs, navigate]);

  // 사용자 수정
  const update = useCallback(async () => {
    const message = allInputs();
    if (!!message) {
      alert(`${message} 입력해 주세요.`);
      return;
    }

    const patchData = {
      ...inputs,
      phone: inputs.phone1 + inputs.phone2 + inputs.phone3,
      belong_device_idx: deviceInputs?.idx,
    };

    const { config } = await requestSecurePatch<UserInputsTypes>(
      apiRoute.user.patchUser,
      {},
      patchData,
      getAccessToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 수정이 완료되었습니다.");
      navigate("/user-manage");
    }
  }, [allInputs, inputs, deviceInputs, getAccessToken, navigate]);

  // 부서(소속) 선택에서 옵션으로 사용할 리스트 조회
  const getRelatedOptions = useCallback(async () => {
    const { config, data } = await requestSecureGet<RelatedTypes[]>(
      apiRoute.belong.getBelongs,
      {},
      getAccessToken()!
    );
    if (config.status >= 200 && config.status < 400)
      setBelongOptions(
        data.sort(function (a, b) {
          return a.store.name < b.store.name
            ? -1
            : a.store.name > b.store.name
            ? 1
            : 0;
        })
      );
  }, [getAccessToken]);

  // 타입이 수정일 때 상세조회
  const getUserData = useCallback(
    async (idx: string) => {
      const { config, data } = await requestSecureGet<UserTypes>(
        apiRoute.user.getUser + idx,
        {},
        getAccessToken()!
      );
      if (config.status >= 200 && config.status < 400) {
        // 사용자 정보
        setInputs((inputs) => ({
          ...inputs,
          idx: idx,
          belong_idx: data.belong_idx,
          class: data.class,
          name: data.name,
          phone1: data.phone.substring(0, 3),
          phone2: data.phone.substring(3, 7),
          phone3: data.phone.substring(7, 11),
          serial: data.serial,
        }));
        // 사용자 단말 정보
        if (data.belong_device?.idx) {
          setDeviceInputs({
            idx: data.belong_device.idx,
            device_info: data.belong_device.device_info,
            serial_number: data.belong_device.serial_number,
          });
        } else {
          setDeviceInputs(null);
        }
      }
    },
    [getAccessToken]
  );

  const getQuries = useCallback(() => {
    const type = searchParams.get("type");
    const userId = searchParams.get("userId");
    if (type) {
      setType(type);
    }
    if (userId) {
      getUserData(userId);
    }
  }, [getUserData, searchParams]);

  useEffect(() => {
    getRelatedOptions();
    getQuries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserInfo
      type={type === "insert" ? "추가" : "수정"}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      belongOptions={belongOptions}
      onClickBelongName={onClickBelongName}
      showModal={showModal}
      setShowModal={setShowModal}
      deviceInputs={deviceInputs}
      setDeviceInputs={setDeviceInputs}
      save={type === "insert" ? insert : update}
    />
  );
};

export default UserInfoContainer;
