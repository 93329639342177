import styled from 'styled-components';

type Props = {
  width?: string;
  flex?: string;
  align?: string;
  justify?: string;
};

type TableProps = {
  height?: string;
};

export const Row = styled.div<Props>`
  ${({ theme }) => theme.flex.row}
  width: ${(props) => props.width || ''};
  align-items: ${(props) => props.align || ''};
  justify-content: ${(props) => props.justify || ''};
  flex: ${(props) => props.flex || ''};
`;

export const Col = styled.div<Props>`
  ${({ theme }) => theme.flex.col}
  width: ${(props) => props.width || ''};
  align-items: ${(props) => props.align || ''};
  justify-content: ${(props) => props.justify || ''};
  flex: ${(props) => props.flex || ''};
`;

export const ModalBackGround = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000075;
`;

export const Modal = styled.div<Props>`
  width: ${(props) => props.width || '450px'};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const modalPadding = '20px';

export const ModalTitleBox = styled(Row)`
  padding: ${modalPadding};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
`;

export const ModalContent = styled(Col)`
  padding: ${modalPadding};
  border-bottom: 1px solid #eee;

  // 마이페이지 > 비밀번호변경 > 메세지
  .msg {
    margin-top: 5px;
    font-size: 0.85rem;
    font-weight: 500;
    color: red;

    &::before {
      font-family: 'FontAwesome';
      content: '\f06a';
      margin-right: 5px;
    }
  }
`;

export const Search = styled.input`
  width: 100%;
  line-height: 40px;
  margin-bottom: 20px;
  padding: 0 10px;
  background-color: #ebedf0;
  border: none;
  border-radius: 5px;

  &::placeholder {
    font-weight: 300;
  }
`;

export const Table = styled(Col)`
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ddd;

  .activeUser {
    background-color: #f6f9fe;
    border-radius: 10px;
    &::after {
      font-family: 'FontAwesome';
      content: '\f058';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
      color: #0f3460;
    }
  }
`;

export const Tbody = styled(Col)<TableProps>`
  width: 100%;
  max-height: ${(props) => props.height || '200px'};
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #ecf0f1;
    border-radius: 25px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 25px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
  }
`;

export const Tr = styled(Col)`
  border-bottom: 1px solid #eee;
  padding: 10px 5px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #f6f9fe;
  }

  div:not(strong) {
    font-size: 0.9rem;
    font-weight: 300;
  }
`;

export const ModalBtns = styled(Row)`
  padding: ${modalPadding};
`;

export const ModalBtn = styled.button`
  width: 100%;
  line-height: 40px;
  background-color: #0f3460;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;

  &:disabled {
    cursor: auto;
    background-color: #8ea1b8;
  }
`;

export const Selected = styled(Row)`
  justify-content: space-between;
  .td {
    ${({ theme }) => theme.flex.row}
    padding: 10px 5px;
  }
  p {
    font-weight: 300;
    margin-right: 5px;
  }
  button {
    margin-right: 5px;
    font-size: 1.2rem;
  }
`;

export const Belongs = styled(Row)`
  flex-wrap: wrap;
  gap: 1vw;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;

  .activeBelong {
    background-color: #0f3460;
    color: #fff;
  }
`;

export const Belong = styled.div`
  background-color: #ecf0f1;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
`;

export const LowBtn = styled.button`
  font-size: 1rem;
  font-weight: 500;
  text-decoration: underline;
  margin-left: 5px;
`;

export const InputBox = styled(Col)`
  position: relative;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Input = styled.input`
  ${({ theme }) => theme.common.input}
  border: 1px solid #ccc;
`;
