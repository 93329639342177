import { ResponsiveLine } from '@nivo/line';

type Props = {
  data: any;
};

const AdminMainChart = ({ data }: Props) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 10, bottom: 25, left: 10 }}
    colors={['#4575f6', '#F8B400']}
    xScale={{ type: 'point' }}
    yFormat=' >-.2f'
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: 36,
      legendPosition: 'middle',
    }}
    axisLeft={null}
    enablePointLabel={true}
    pointLabel='y'
    pointSize={10}
    pointBorderWidth={2}
    pointLabelYOffset={-12}
    useMesh={true}
    enableGridY={false}
    theme={{
      grid: {
        line: {
          stroke: '#ddd',
          strokeWidth: 1,
          strokeDasharray: '5 5',
        },
      },
    }}
    animate={false}
  />
);

export default AdminMainChart;
