import Table from '../Table';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.type';

type Props = {
  columns: TableColumnTypes[];
  tableData: any;
  filter?: any;
  searchValue?: string | null;
};

const TableContainer = ({ columns, tableData, searchValue }: Props) => {
  return (
    <Table columns={columns} tableData={tableData} searchValue={searchValue} />
  );
};

export default TableContainer;
