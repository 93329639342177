import { Navigate, Route, Routes } from 'react-router-dom';
import AdminMainContainer from '@components/AdminMain/containers/AdminMainContainer';
import MyPageContainer from '@components/MyPage/containers/MyPageContainer';
import CurrentContainer from '@components/Current/containers/CurrentContainer';
import HistoryContainers from '@components/History/containers/HistoryContainers';
import UserManageContainer from '@components/UserManage/containers/UserManageContainer';
import UserInfoContainer from '@components/UserManage/containers/UserInfoContainer';
import DeviceManageContainer from '@components/DeviceManage/containers/DeviceManageContainer';
import DeviceInfoContainer from '@components/DeviceManage/containers/DeviceInfoContainer';
import KioskManageContainer from '@components/KioskManage/containers/KioskManageContainer';
import KioskInfoContainer from '@components/KioskManage/containers/KioskInfoContainer';
import BelongManageContainer from '@components/BelongManage/containers/BelongManageContainer';
import BelongInfoContainer from '@components/BelongManage/containers/BelongInfoContainer';

const AdminRoleNavigaiton = () => {
  return (
    <Routes>
      <Route path='' element={<AdminMainContainer />} />
      <Route path='mypage' element={<MyPageContainer />} />
      <Route path='current' element={<CurrentContainer />} />
      <Route path='history' element={<HistoryContainers />} />
      <Route path='user-manage' element={<UserManageContainer />} />
      <Route path='user-info' element={<UserInfoContainer />} />
      <Route path='device-manage' element={<DeviceManageContainer />} />
      <Route path='device-info' element={<DeviceInfoContainer />} />
      <Route path='kiosk-manage' element={<KioskManageContainer />} />
      <Route path='kiosk-info' element={<KioskInfoContainer />} />
      <Route path='belong-manage' element={<BelongManageContainer />} />
      <Route path='belong-info' element={<BelongInfoContainer />} />
      <Route path='*' element={<Navigate to='' />}></Route>
    </Routes>
  );
};

export default AdminRoleNavigaiton;
