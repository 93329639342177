import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import useToken from '@hooks/useToken';
import useKiosk from '@hooks/useKiosk';
import MainNavigation from './components/MainNavigation';
import LoginNavigation from './components/LoginNavigation';
import UserRoleNavigation from './components/UserRoleNavigation';

const RootNavigation = () => {
  const { getAccessToken } = useToken();
  const { getKiosk } = useKiosk();
  const uid = useMemo(() => getKiosk().uid, [getKiosk]);
  return (
    <Routes>
      <Route
        path='*'
        element={
          getAccessToken() ? (
            <MainNavigation />
          ) : uid ? (
            <UserRoleNavigation />
          ) : (
            <LoginNavigation />
          )
        }
      />
    </Routes>
  );
};

export default RootNavigation;
