import * as S from '@styles/FormStyle';
import SelectUserDeviceContainer from '../containers/SelectUserDeviceContainer';
import Select, { SingleValue } from 'react-select';
import { UserInputsTypes } from '@typedef/components/UserManage/components/user.inputs.types';
import { ShopDeviceTypes } from '@typedef/components/DeviceManage/shop.device.types';
import { RelatedTypes } from '@typedef/components/BelongManage/related.types';

type Props = {
  type: string | null;
  inputs: UserInputsTypes;
  deviceInputs: ShopDeviceTypes | null;
  onChangeInputs: (e: React.FormEvent<HTMLInputElement>) => void;
  belongOptions: RelatedTypes[];
  onClickBelongName: (option: SingleValue<RelatedTypes> | null) => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDeviceInputs: React.Dispatch<React.SetStateAction<ShopDeviceTypes | null>>;
  save: () => Promise<void>;
};

const UserInfo = ({
  type,
  inputs,
  onChangeInputs,
  onClickBelongName,
  showModal,
  setShowModal,
  belongOptions,
  deviceInputs,
  setDeviceInputs,
  save,
}: Props) => {
  return (
    <S.Page>
      <S.TitleBox>
        <h2>사용자 {type}</h2>
        <S.Btn type='button' onClick={save} className='active'>
          저장
        </S.Btn>
      </S.TitleBox>
      <S.Form>
        <h3>사용자 정보</h3>
        <S.Col flex='1'>
          <S.InputBox>
            <p>
              이름<span>*</span>
            </p>
            <S.Input
              name='name'
              onChange={onChangeInputs}
              defaultValue={inputs.name}
              placeholder='이름'
              autoFocus
            />
          </S.InputBox>
          <S.InputBox>
            <p>
              개인번호(군번)<span>*</span>
            </p>
            <S.Input
              name='serial'
              onChange={onChangeInputs}
              defaultValue={inputs.serial}
              placeholder='개인번호(군번)'
            />
          </S.InputBox>
          <S.InputBox>
            <p>부서(소속)</p>
            <Select<RelatedTypes>
              options={belongOptions}
              getOptionValue={(data: RelatedTypes) => data.store.idx}
              getOptionLabel={(data: RelatedTypes) => data.store.name}
              defaultValue={belongOptions.filter(
                (belong) => belong.store.idx === inputs.belong_idx,
              )}
              key={inputs.belong_idx}
              onChange={onClickBelongName}
              placeholder='미선택 시 계정 소속으로 지정'
              isClearable={true}
              className='select'
            />
          </S.InputBox>
          <S.InputBox>
            <p>
              휴대전화<span>*</span>
            </p>
            <S.Input
              name='phone1'
              onChange={onChangeInputs}
              defaultValue={inputs.phone1}
              maxLength={3}
              className='phone'
            />
            <S.Input
              name='phone2'
              onChange={onChangeInputs}
              defaultValue={inputs.phone2}
              maxLength={4}
              className='phone'
            />
            <S.Input
              name='phone3'
              onChange={onChangeInputs}
              defaultValue={inputs.phone3}
              maxLength={4}
              className='phone'
            />
          </S.InputBox>
          <S.InputBox>
            <p>
              직급(계급)<span>*</span>
            </p>
            <S.Input
              name='class'
              onChange={onChangeInputs}
              defaultValue={inputs.class}
              placeholder='직급(계급)'
            />
          </S.InputBox>
        </S.Col>
      </S.Form>
      <S.Form>
        <h3>단말 정보</h3>
        {deviceInputs ? (
          <S.Col>
            <S.Row>
              <S.InputBox>
                <p>모델명</p>
                <S.Col>
                  <S.Input
                    defaultValue={deviceInputs.device_info.device.pet_name}
                    key={deviceInputs.device_info.device.pet_name}
                    placeholder='모델명'
                    disabled
                  />
                </S.Col>
                <S.AddBtn type='button' onClick={() => setShowModal(true)}>
                  단말 정보 수정
                </S.AddBtn>
              </S.InputBox>
            </S.Row>
            <S.InputBox>
              <p>색상</p>
              <S.Col>
                <S.Input
                  defaultValue={deviceInputs.device_info.color.name}
                  key={deviceInputs.device_info.color.name}
                  placeholder='색상'
                  disabled
                />
              </S.Col>
            </S.InputBox>
            <S.InputBox>
              <p>일련번호</p>
              <S.Col>
                <S.Input
                  defaultValue={deviceInputs.serial_number}
                  key={deviceInputs.serial_number}
                  placeholder='일련번호'
                  disabled
                />
              </S.Col>
            </S.InputBox>
          </S.Col>
        ) : (
          <S.Row align='center'>
            <p>단말 정보가 없습니다.</p>
            <button
              type='button'
              onClick={() => setShowModal(true)}
              className='lowBtn'>
              추가하기
            </button>
          </S.Row>
        )}
        {showModal && (
          <SelectUserDeviceContainer
            setShowModal={setShowModal}
            setDeviceInputs={setDeviceInputs}
          />
        )}
      </S.Form>
    </S.Page>
  );
};

export default UserInfo;
