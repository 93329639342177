import Login from "../Login";
import { useState, useCallback } from "react";
import useToken from "@hooks/useToken";
import useUser from "@hooks/useUser";
import useKiosk from "@hooks/useKiosk";
import { apiRoute, requestSecurePost } from "@libs/api";

const LoginContainer = () => {
  const { getAccessToken, setAccessToken, setRefreshToken } = useToken();
  const { setUser } = useUser();
  const { setKiosk } = useKiosk();
  const [inputs, setInputs] = useState({
    adminId: "",
    password: "",
    userId: "",
  });

  const onChangeInputs = useCallback(
    (e: { target: HTMLInputElement }) => {
      const { name, value } = e.target;
      setInputs({
        ...inputs,
        [name]: value,
      });
    },
    [inputs]
  );

  // 관리자 로그인
  const adminLogin = useCallback(async () => {
    if (inputs.adminId && inputs.password) {
      const { config, data } = await requestSecurePost<{
        access_token: string;
        name: string;
        refresh_token: string;
        role: "ROLE_JSOL" | "ROLE_PARENT" | "ROLE_USER";
        shop_idx: number;
      }>(
        apiRoute.login.postAdmin,
        {},
        {
          username: inputs.adminId,
          password: inputs.password,
        },
        getAccessToken()!
      );
      if (config.status >= 200 && config.status < 400) {
        console.log(data);
        setAccessToken(data.access_token);
        setRefreshToken(data.refresh_token);
        setUser({
          name: data.name,
          role: data.role,
          shop_idx: data.shop_idx,
        });
        window.location.reload();
      } else {
        alert("아이디 또는 비밀번호를 잘못 입력했습니다.");
        return;
      }
    } else {
      alert("아이디와 비밀번호를 입력해 주세요.");
    }
  }, [getAccessToken, inputs, setAccessToken, setRefreshToken, setUser]);

  // 사용자(태블릿) 로그인
  const userLogin = useCallback(async () => {
    const id = inputs.userId;
    const { config, data } = await requestSecurePost<any>(
      apiRoute.login.postKiosk,
      {},
      { uid: id },
      getAccessToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      // const fileData = id;
      // const blob = new Blob([fileData], { type: 'text/plain' });
      // const url = URL.createObjectURL(blob);
      // const link = document.createElement('a');
      // link.download = 'info';
      // link.href = url;
      // link.click();
      setKiosk(data);
      window.location.reload();
    } else {
      alert("로그인에 실패했습니다. 고유번호를 확인해 주세요.");
      return;
    }
  }, [getAccessToken, inputs.userId, setKiosk]);

  return (
    <Login
      onChangeInputs={onChangeInputs}
      adminLogin={adminLogin}
      userLogin={userLogin}
    />
  );
};

export default LoginContainer;
