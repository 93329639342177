import useUser from '@hooks/useUser';
import { useState, useMemo } from 'react';
import Header from '../Header';

const HeaderContainer = () => {
  const { getUser } = useUser();
  const userName = useMemo(() => getUser().name, [getUser]);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Header userName={userName} showMenu={showMenu} setShowMenu={setShowMenu} />
  );
};

export default HeaderContainer;
