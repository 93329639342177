import CheckBox from '../components/CheckBox';
import { CheckBoxTypes } from '@typedef/components/Common/Input/checkbox/checkbox.type';

const CheckBoxContainer = ({
  id,
  defaultChecked,
  onChangeFunc,
}: CheckBoxTypes) => {
  return (
    <CheckBox
      id={id}
      defaultChecked={defaultChecked}
      onChangeFunc={onChangeFunc}
    />
  );
};

export default CheckBoxContainer;
