import * as S from '@styles/FormStyle';
import { KioskInputsDataTypes } from '@typedef/components/KioskManage/components/kioskInputs.data.types';
import { UserTypes } from '@typedef/components/UserManage/user.types';
import SelectKioskUserContainer from '../containers/SelectKioskUserContainer';

type Props = {
  type: string | null;
  inputs: KioskInputsDataTypes;
  userInputs: UserTypes[] | null;
  onChangeInputs: (e: React.FormEvent<HTMLInputElement>) => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUserInputs: React.Dispatch<React.SetStateAction<UserTypes[] | null>>;
  save: () => Promise<void>;
};

const KioskInfo = ({
  type,
  inputs,
  onChangeInputs,
  showModal,
  setShowModal,
  userInputs,
  setUserInputs,
  save,
}: Props) => {
  return (
    <S.Page>
      <S.TitleBox>
        <h2>단말보관함 {type}</h2>
        <S.Btn type='button' onClick={save} className='active'>
          저장
        </S.Btn>
      </S.TitleBox>
      <S.Form>
        <h3>단말보관함 정보</h3>
        <S.Col>
          <S.InputBox>
            <p>
              보관함명(위치)<span>*</span>
            </p>
            <S.Input
              name='name'
              onChange={onChangeInputs}
              defaultValue={inputs.name}
              placeholder='보관함명(위치)'
            />
          </S.InputBox>
          <S.InputBox>
            <p>
              최대 보관개수<span>*</span>
            </p>
            <S.Input
              name='max'
              onChange={onChangeInputs}
              defaultValue={inputs.max}
              placeholder='숫자만 입력해 주세요.'
            />
          </S.InputBox>
          <S.InputBox>
            <p>메모</p>
            <S.Input
              name='memo'
              onChange={onChangeInputs}
              defaultValue={inputs.memo}
              placeholder='메모'
            />
          </S.InputBox>
        </S.Col>
      </S.Form>
      <S.Form>
        <S.Col>
          <h3>사용자 정보&nbsp;{userInputs && `(${userInputs.length})`}</h3>
          <span className='subtitle'>
            해당 단말보관함을 사용하는
            <br /> 사용자 정보입니다.
          </span>
        </S.Col>
        {userInputs && userInputs.length !== 0 ? (
          <S.SelectedUsers>
            {userInputs.map((user, idx) => (
              <S.SelectedUser key={user.idx}>
                <strong>{user.name}</strong>
                <div>({user.serial})</div>
              </S.SelectedUser>
            ))}
            <S.EditUser type='button' onClick={() => setShowModal(true)}>
              사용자 정보 수정
            </S.EditUser>
          </S.SelectedUsers>
        ) : (
          <S.Row align='center' className='selfStart'>
            <p>사용자 정보가 없습니다.</p>
            <button
              type='button'
              onClick={() => setShowModal(true)}
              className='lowBtn'>
              추가하기
            </button>
          </S.Row>
        )}
        {showModal && (
          <SelectKioskUserContainer
            setShowModal={setShowModal}
            setUserInputs={setUserInputs}
            userInputs={userInputs}
          />
        )}
      </S.Form>
    </S.Page>
  );
};

export default KioskInfo;
