import useUser from '@hooks/useUser';
import { GnbItemTypes } from '@typedef/components/Common/GNB/gnb.type';
import { useMemo } from 'react';
import GNB from '../GNB';
import {
  FiRepeat,
  FiClock,
  FiUsers,
  FiTablet,
  FiFolder,
  FiShare2,
} from 'react-icons/fi';
const jsolNavs: GnbItemTypes[] = [
  {
    name: '관리자관리',
    path: '/admin-manage',
    icon: FiUsers,
  },
];

const adminNavs: GnbItemTypes[] = [
  {
    name: '입출고현황',
    path: 'current',
    icon: FiRepeat,
  },
  {
    name: '입출고이력',
    path: 'history',
    icon: FiClock,
  },
  {
    name: '단말관리',
    path: 'device-manage',
    icon: FiTablet,
  },
  {
    name: '사용자관리',
    path: 'user-manage',
    icon: FiUsers,
  },
  {
    name: '단말보관함관리',
    path: 'kiosk-manage',
    icon: FiFolder,
  },
  {
    name: '부서관리',
    path: 'belong-manage',
    icon: FiShare2,
  },
];

const GNBContainer = () => {
  const { getUser } = useUser();
  const role = useMemo(() => getUser().role, [getUser]);

  return <GNB navList={role.includes('JSOL') ? jsolNavs : adminNavs} />;
};

export default GNBContainer;
