import styled from 'styled-components';

export const CSearch = styled.div`
  ${({ theme }) => theme.flex.row}
  align-items: center;

  input {
    width: 350px;
    line-height: 40px;
    border-radius: 5px;
    padding: 0 20px;
    background-color: #fff;
    border: 1px solid #eee;
    outline: none;
    font-weight: 300;
    &::placeholder {
      font-size: 0.9rem;
      font-weight: 300;
    }
  }

  button {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #eee;
    color: #112d4e;
  }
`;
