import * as S from '@styles/FormStyle';
import { MyPageTypes } from '@typedef/components/MyPage/mypage.types';
import ChangePasswordContainer from './containers/ChangePasswordContainer';

type Props = {
  inputs: MyPageTypes;
  onChangeInputs: (e: React.FormEvent<HTMLInputElement>) => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  update: () => Promise<void>;
};

const MyPage = ({
  onChangeInputs,
  inputs,
  update,
  showModal,
  setShowModal,
}: Props) => {
  return (
    <S.Page>
      <S.TitleBox>
        <h2>마이페이지</h2>
        <S.Btn type='button' onClick={update} className='active'>
          저장
        </S.Btn>
      </S.TitleBox>
      <S.Form>
        <h3>계정 정보</h3>
        <S.Col>
          <S.InputBox>
            <p>아이디</p>
            <S.Input defaultValue={inputs.id} placeholder='아이디' disabled />
          </S.InputBox>
        </S.Col>
      </S.Form>
      <S.MyPageRow>
        <h3>보안 정보</h3>
        <button type='button' onClick={() => setShowModal(true)}>
          비밀번호 변경
        </button>
      </S.MyPageRow>
      <S.Form>
        <h3>부서 정보</h3>
        <S.Col>
          <S.InputBox>
            <p>부서명</p>
            <S.Input
              name='name'
              onChange={onChangeInputs}
              defaultValue={inputs.name}
              placeholder='소속명'
            />
          </S.InputBox>
          <S.InputBox>
            <p>대표전화</p>
            <S.Input
              name='phone'
              onChange={onChangeInputs}
              defaultValue={inputs.phone}
              placeholder="'-' 없이 숫자만 입력"
            />
          </S.InputBox>
        </S.Col>
      </S.Form>
      <S.Form>
        <h3>관리자 정보</h3>
        <S.Col>
          <S.InputBox>
            <p>관리자 이름</p>
            <S.Input
              name='manager_name'
              onChange={onChangeInputs}
              // defaultValue={inputs.manager_name}
              placeholder='관리자 이름'
            />
          </S.InputBox>
          <S.InputBox>
            <p>관리자 휴대전화</p>
            <S.Input
              name='manager_phone1'
              onChange={onChangeInputs}
              // defaultValue={inputs.manager_phone1}
              maxLength={3}
              className='phone'
            />
            <S.Input
              name='manager_phone2'
              onChange={onChangeInputs}
              // defaultValue={inputs.manager_phone2}
              maxLength={4}
              className='phone'
            />
            <S.Input
              name='manager_phone3'
              maxLength={4}
              onChange={onChangeInputs}
              // defaultValue={inputs.manager_phone3}
              className='phone'
            />
          </S.InputBox>
        </S.Col>
      </S.Form>
      {showModal && <ChangePasswordContainer setShowModal={setShowModal} />}
    </S.Page>
  );
};

export default MyPage;
