import KioskManage from "../KioskManage";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.type";
import { NoColumn } from "@components/Common/CTable/components/IdxColumn";
import CheckBoxContainer from "@components/Common/CInput/containers/CheckBoxContainer";
import { apiRoute, requestSecureDelete, requestSecureGet } from "@libs/api";
import useToken from "@hooks/useToken";
import { KioskDataTypes } from "@typedef/components/KioskManage/kiosk.data.types";
import { useNavigate } from "react-router-dom";
import useUser from "@hooks/useUser";

const KioskManageContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();
  const { getUser } = useUser();
  const [tableData, setTableData] = useState<KioskDataTypes[]>([]);
  const [selectRows, setSelectRows] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // 체크박스 선택
  const onChangeCheckBox = useCallback(
    (idx: number) => {
      if (selectRows.includes(idx)) {
        const filter = selectRows.filter((row) => row !== idx);
        return setSelectRows(filter);
      }
      setSelectRows((row) => [...row, idx]);
    },
    [selectRows]
  );

  // 키오스크 관리 (등록, 수정)
  const manage = useCallback(
    (type: "insert" | "update") => {
      let url = `/kiosk-info?type=${type}`;
      if (type === "update") {
        if (selectRows.length > 1) {
          alert("수정할 정보를 하나만 선택해 주세요.");
          return;
        } else if (selectRows.length === 0) {
          alert("수정할 정보를 선택해 주세요.");
          return;
        }
        url += `&kioskId=${selectRows[0]}`;
      }
      navigate(url);
    },
    [navigate, selectRows]
  );

  // 단말보관함 정보 삭제
  const deleteKioskData = useCallback(async () => {
    if (selectRows.length === 0) {
      alert("삭제할 데이터를 선택해 주세요.");
      return;
    }
    if (
      window.confirm(
        "단말보관함을 사용 중인 사용자들의 단말보관함 정보가 사라집니다.\n삭제하시겠습니까?"
      )
    ) {
      await Promise.all(
        selectRows.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.kiosk.deleteKiosk + idx,
                {},
                getAccessToken()!
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                } else {
                  reject(`http request failed with ${data.config.status}`);
                }
              });
            })
        )
      )
        .then(() => {
          alert("성공적으로 삭제가 완료되었습니다.");
          window.location.reload();
        })
        .catch(() => {
          alert("삭제 실패");
        });
    }
  }, [getAccessToken, selectRows]);

  // 테이블 검색
  const searchTable = useCallback(() => {
    if (inputRef.current) {
      setSearchValue(inputRef.current.value);
    }
  }, []);

  // 테이블 검색내역 삭제
  const clearSearchValue = useCallback(() => {
    const { current } = inputRef;
    if (current) {
      current.value = "";
    }
    setSearchValue(null);
  }, []);

  const columns: TableColumnTypes[] = useMemo(
    () => [
      {
        Header: "",
        accessor: "checkbox",
        disableSortBy: true,
        width: 50,
        Cell: ({ row }: { row: any }) => {
          return (
            <CheckBoxContainer
              id={row.original.idx}
              defaultChecked={selectRows.includes(row.original.idx)}
              onChangeFunc={onChangeCheckBox}
            />
          );
        },
      },
      NoColumn("idx"),
      {
        accessor: "name",
        Header: "보관함명(위치)",
      },
      {
        accessor: "uid",
        Header: "고유번호",
      },
      {
        accessor: "max",
        Header: "최대보관개수",
      },
      {
        accessor: "memo",
        Header: "메모",
      },
    ],
    [onChangeCheckBox, selectRows]
  );

  const getTableData = useCallback(async () => {
    const { config, data } = await requestSecureGet<KioskDataTypes[]>(
      apiRoute.kiosk.getKiosks + `/list/${getUser().shop_idx}`,
      {},
      getAccessToken()!
    );
    if (config.status >= 200 && config.status < 400) setTableData(data);
  }, [getAccessToken, getUser]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  return (
    <KioskManage
      columns={columns}
      tableData={tableData}
      manage={manage}
      deleteKioskData={deleteKioskData}
      inputRef={inputRef}
      searchValue={searchValue}
      searchTable={searchTable}
      clearSearchValue={clearSearchValue}
    />
  );
};

export default KioskManageContainer;
