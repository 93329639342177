import AdminInfoContainer from '@components/AdminManage/containers/AdminInfoContainer';
import AdminManageContainer from '@components/AdminManage/containers/AdminManageContainer';
import JsolMain from '@components/JsolMain/JsolMain';
import { Navigate, Route, Routes } from 'react-router-dom';

const JSolRoleNavigation = () => {
  return (
    <Routes>
      <Route path='' element={<JsolMain />} />
      <Route path='admin-manage' element={<AdminManageContainer />} />
      <Route path='admin-info' element={<AdminInfoContainer />} />
      <Route path='*' element={<Navigate to='' />} />
    </Routes>
  );
};

export default JSolRoleNavigation;
