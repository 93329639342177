import * as S from '@styles/PresenterStyle';
import TableContainer from '@components/Common/CTable/containers/TableContainer';
import { RefObject } from 'react';
import CSearchContainer from '@components/Common/CSearch/containers/CSearchContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
  columns: any;
  tableData: any;
  searchValue: string | null;
  inputRef: RefObject<HTMLInputElement>;
  searchTable: () => void;
  clearSearchValue: () => void;
};

const Current = ({
  columns,
  tableData,
  searchValue,
  inputRef,
  searchTable,
  clearSearchValue,
}: Props) => {
  return (
    <S.Presenter>
      <S.TitleBox>
        <h2>입출고현황</h2>
      </S.TitleBox>
      <S.FilterBox>
        <CSearchContainer func={searchTable} inputRef={inputRef} />
        <div className='apply'>
          {searchValue && (
            <S.SearchValue>
              <p>{searchValue}</p>
              <button type='button' onClick={clearSearchValue}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </S.SearchValue>
          )}
        </div>
      </S.FilterBox>
      <TableContainer
        columns={columns}
        tableData={tableData}
        searchValue={searchValue}
      />
    </S.Presenter>
  );
};

export default Current;
