import SelectKioskUser from '../components/SelectKioskUser';
import { useState, useEffect, useCallback } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { RelatedTypes } from '@typedef/components/BelongManage/related.types';
import { UserTypes } from '@typedef/components/UserManage/user.types';

type Props = {
  userInputs: UserTypes[] | null;
  setUserInputs: React.Dispatch<React.SetStateAction<UserTypes[] | null>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const SelectKioskUserContainer = ({
  userInputs,
  setUserInputs,
  setShowModal,
}: Props) => {
  const { getAccessToken } = useToken();
  const [belongs, setBelongs] = useState<RelatedTypes[] | null>(null);
  const [allUsers, setAllUsers] = useState<UserTypes[] | null>(null);
  const [filterUsers, setFilterUsers] = useState<UserTypes[] | null>(null);
  const [selectedBelong, setSelectedBelong] = useState<number | 'all'>('all');
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  // 모달 닫기
  const onClickCloseModal = useCallback(
    (e: React.MouseEvent) => {
      const { id } = e.target as HTMLDivElement;
      if (id === 'backGround') {
        setShowModal(false);
      }
    },
    [setShowModal],
  );

  // 소속 필터 함수
  const onClickBelong = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { id } = e.target as HTMLDivElement;
      if (id === 'all') {
        setFilterUsers(null);
        setSelectedBelong('all');
        return;
      }
      const filtered = allUsers?.filter(
        (user) => String(user.belong_idx) === id,
      );
      console.log(filtered);
      if (filtered) {
        setFilterUsers(filtered);
      }
      setSelectedBelong(+id);
    },
    [allUsers],
  );

  // 사용자 클릭 함수
  const onClickUser = useCallback(
    (idx: string) => {
      if (selectedUsers?.includes(idx)) {
        setSelectedUsers(selectedUsers.filter((userIdx) => userIdx !== idx));
      } else {
        setSelectedUsers([...selectedUsers, idx]);
      }
    },
    [selectedUsers],
  );

  // 추가 버튼
  const onClickAddBtn = useCallback(() => {
    const filtered = allUsers?.filter((user) =>
      selectedUsers.includes(user.idx),
    );
    if (filtered) {
      setUserInputs(filtered);
    }
    setShowModal(false);
  }, [allUsers, selectedUsers, setUserInputs, setShowModal]);

  // 전체 부서(소속) 데이터
  const getBelongData = useCallback(async () => {
    const { config, data } = await requestSecureGet<RelatedTypes[]>(
      apiRoute.belong.getBelongs,
      {},
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400) setBelongs(data);
  }, [getAccessToken]);

  // 단말보관함 사용자 선택을 위한 user 조회
  const getUserData = useCallback(async () => {
    const { config, data } = await requestSecureGet<UserTypes[]>(
      apiRoute.user.getUsers,
      {},
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400) setAllUsers(data);
  }, [getAccessToken]);

  // 이미 선택한 유저정보가 있다면 selectedUsers에 추가 (actvie 스타일)
  const getSelectedUser = useCallback(() => {
    if (userInputs) {
      const users = userInputs.map((user) => user.idx);
      setSelectedUsers(users);
    }
  }, [userInputs]);

  useEffect(() => {
    getSelectedUser();
    getBelongData();
    getUserData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectKioskUser
      onClickCloseModal={onClickCloseModal}
      belongs={belongs}
      onClickBelong={onClickBelong}
      selectedBelong={selectedBelong}
      users={filterUsers ? filterUsers : allUsers}
      onClickUser={onClickUser}
      selectedUsers={selectedUsers}
      onClickAddBtn={onClickAddBtn}
    />
  );
};

export default SelectKioskUserContainer;
