import * as S from './styles/UserMainStyle';
import image from '@assets/image';
import { UserTypes } from '@typedef/components/UserManage/user.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';

type Props = {
  name: string;
  kioskUsers: UserTypes[] | null;
  logout: () => void;
};

const UserMain = ({ name, kioskUsers, logout }: Props) => {
  return (
    <S.UserMain>
      <S.Left>
        <S.Col>
          <img src={image.idmLogo} alt='로고' />
        </S.Col>
        <S.Col align='center'>
          <S.Col align='center' mb='25px'>
            <h1 key='입고'>
              {
                kioskUsers?.filter(
                  (user) => user.belong_device?.status.name === '입고',
                ).length
              }
            </h1>
            <p>보관중</p>
          </S.Col>
          <S.Col align='center' mb='25px'>
            <h1 key='출고'>
              {
                kioskUsers?.filter(
                  (user) => user.belong_device?.status.name === '출고',
                ).length
              }
            </h1>
            <p>사용중</p>
          </S.Col>
          <S.Col align='center'>
            <h1>{kioskUsers?.length}</h1>
            <p>전체</p>
          </S.Col>
        </S.Col>
      </S.Left>
      <S.Right>
        <S.Row align='center' mb='50px'>
          <img src={image.campLogo} alt='군부대로고' />
          <S.Col width='100%'>
            <S.Row justify='space-between'>
              <h1>{name}</h1>
              <S.Logout type='button' onClick={logout}>
                로그아웃
              </S.Logout>
            </S.Row>
            <p className='fw300'>사단이름</p>
          </S.Col>
        </S.Row>
        <S.Users>
          {kioskUsers?.map((user) => {
            const status = user.belong_device?.status.name;
            return (
              <S.User key={user.idx}>
                <S.Status className={status === '입고' ? 'put' : ''}>
                  <FontAwesomeIcon icon={status === '입고' ? faCheck : faX} />
                </S.Status>
                <S.Info>
                  <p className='name'>{user.name}</p>
                  <p className='belong'>{user.belong?.name}</p>
                  <p>{user.serial}</p>
                </S.Info>
                <S.StatusName className={status === '입고' ? 'put' : ''}>
                  <h3>
                    {!!!status
                      ? '단말정보없음'
                      : status === '입고'
                      ? '보관중'
                      : '사용중'}
                  </h3>
                  {/* {status === '입고' ? (
                    <p className='fw300'>
                      {moment(user.belong_device?.in_date).format(
                        'YYYY-MM-DD hh:mm:ss',
                      )}
                    </p>
                  ) : (
                    <p className='fw300'>
                      {' '}
                      {moment(user.belong_device?.out_date).format(
                        'YYYY-MM-DD hh:mm:ss',
                      )}
                    </p>
                  )} */}
                </S.StatusName>
              </S.User>
            );
          })}
        </S.Users>
      </S.Right>
    </S.UserMain>
  );
};

export default UserMain;
