import styled from 'styled-components';

export const Page = styled.div`
  height: 100vh;
  max-width: 1920px;
  ${({ theme }) => theme.flex.col}
`;

export const Content = styled.div`
  ${({ theme }) => theme.flex.row}
  flex: 1;
`;

export const Routes = styled.div`
  flex: 1;
  ${({ theme }) => theme.flex.col}
  border-left: 1px solid #eee;
`;
