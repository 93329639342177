import * as S from '@styles/FormStyle';
import { BelongInputsDataTypes } from '@typedef/components/BelongManage/components/belongInputs.data.types';

type Props = {
  type: string | null;
  inputs: BelongInputsDataTypes;
  onChangeInputs: (e: React.FormEvent<HTMLInputElement>) => void;
  regexId: (id: string) => Promise<void>;
  idMessage: string | null;
  regexPassword: (pw: string) => void;
  pwMessage: string | null;
  samePassword: (pw: string) => void;
  pwConfirmMessage: string | null;
  insert: () => void;
  update: () => void;
};

const BelongInfo = ({
  type,
  inputs,
  onChangeInputs,
  regexId,
  idMessage,
  regexPassword,
  pwMessage,
  pwConfirmMessage,
  samePassword,
  insert,
  update,
}: Props) => {
  return (
    <S.Page>
      <S.TitleBox>
        <h2>{type === 'insert' ? '부서 추가' : '부서 정보 수정'}</h2>
        <S.Btn
          type='button'
          onClick={type === 'insert' ? insert : update}
          className='active'>
          저장
        </S.Btn>
      </S.TitleBox>
      <S.Form>
        <h3>계정 정보</h3>
        <S.Col flex='1'>
          <S.InputBox>
            <p>아이디</p>
            <S.Input
              onBlur={(e) => regexId(e.target.value)}
              defaultValue={inputs.manager_id}
              disabled={type === 'update'}
              placeholder='아이디'
            />
            <span className={idMessage ? 'msg' : ''}>
              {idMessage && idMessage}
            </span>
          </S.InputBox>
          {type === 'insert' && (
            <>
              <S.InputBox>
                <p>비밀번호</p>
                <S.Input
                  type='password'
                  onBlur={(e) => regexPassword(e.target.value)}
                  placeholder='8-20 영문, 숫자'
                />
                <span className={pwMessage ? 'msg' : ''}>
                  {pwMessage && pwMessage}
                </span>
              </S.InputBox>
              <S.InputBox>
                <p>비밀번호 확인</p>
                <S.Input
                  type='password'
                  onBlur={(e) => samePassword(e.target.value)}
                  placeholder='비밀번호 확인'
                />
                <span className={pwConfirmMessage ? 'msg' : ''}>
                  {pwConfirmMessage}
                </span>
              </S.InputBox>
            </>
          )}
        </S.Col>
      </S.Form>
      <S.Form>
        <h3>부서 정보</h3>
        <S.Col>
          <S.InputBox>
            <p>부서명</p>
            <S.Input
              name='name'
              onChange={onChangeInputs}
              defaultValue={inputs.name}
              placeholder='소속명'
            />
          </S.InputBox>
          <S.InputBox>
            <p>대표전화</p>
            <S.Input
              name='phone'
              onChange={onChangeInputs}
              defaultValue={inputs.phone}
              placeholder="'-' 없이 숫자만 입력"
            />
          </S.InputBox>
        </S.Col>
      </S.Form>
      <S.Form>
        <h3>관리자 정보</h3>
        <S.Col>
          <S.InputBox>
            <p>관리자 이름</p>
            <S.Input
              name='manager_name'
              onChange={onChangeInputs}
              defaultValue={inputs.manager_name}
              placeholder='관리자 이름'
            />
          </S.InputBox>
          <S.InputBox>
            <p>관리자 휴대전화</p>
            <S.Input
              name='manager_phone1'
              onChange={onChangeInputs}
              defaultValue={inputs.manager_phone1}
              maxLength={3}
              className='phone'
            />
            <S.Input
              name='manager_phone2'
              onChange={onChangeInputs}
              defaultValue={inputs.manager_phone2}
              maxLength={4}
              className='phone'
            />
            <S.Input
              name='manager_phone3'
              maxLength={4}
              onChange={onChangeInputs}
              defaultValue={inputs.manager_phone3}
              className='phone'
            />
          </S.InputBox>
        </S.Col>
      </S.Form>
    </S.Page>
  );
};

export default BelongInfo;
