import styled from 'styled-components';

type Props = {
  width?: string;
  height?: string;
  align?: string;
  justify?: string;
  mt?: string;
  mb?: string;
  bgc?: string;
  flex?: string;
};

export const Row = styled.div<Props>`
  ${({ theme }) => theme.flex.row}
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || ''};
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justify || ''};
  margin-top: ${(props) => props.mt || '0px'};
  margin-bottom: ${(props) => props.mb || '0px'};
  background-color: ${(props) => props.bgc || ''};
  flex: ${(props) => props.flex || ''};
`;

export const Col = styled.div<Props>`
  ${({ theme }) => theme.flex.col}
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || ''};
  align-items: ${(props) => props.align || ''};
  justify-content: ${(props) => props.justify || ''};
  margin-bottom: ${(props) => props.mb || '0px'};
  background-color: ${(props) => props.bgc || ''};
  flex: ${(props) => props.flex || ''};
`;

export const Main = styled(Row)`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

export const Left = styled(Col)`
  width: 68vw;
  height: 100%;
  padding: 30px;
`;

export const Right = styled(Col)`
  flex: 1;
  height: 100%;
  padding: 30px;
  border-left: 1px solid #eee;
`;

export const Title = styled(Row)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  p {
    font-weight: 300;
  }
`;

export const Kiosks = styled(Col)`
  margin-bottom: 30px;

  .arrow {
    width: 30px;
    height: 30px;
    border: 1px solid #eee;
    border-radius: 50px;
    margin-left: 15px;
  }

  #slider-wrapper {
    width: calc(68vw - 60px);
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
  .slick-list {
    margin: 0 -15px;
  }

  // 5개 미만
  .isNotFive {
    width: calc(68vw - 60px);
    ${({ theme }) => theme.flex.row}

    .kiosk {
      width: 20%;
      padding-left: 0;
      padding-right: 30px;
    }
  }
`;

export const Kiosk = styled(Col)`
  position: relative;
  padding: 0 15px;

  .index {
    width: 100%;
    height: 15px;
    background-color: #edf3f9;
    border-radius: 10px 10px 0 0;
  }
  .folderBack {
    width: 60%;
    height: 15px;
    background-color: #fff;
    transform: skew(30deg);
    position: absolute;
    top: 0;
    right: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const KioskContent = styled(Col)`
  padding: 2vw 1vw;
  background-color: #edf3f9;
  border-radius: 0 10px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  h3 {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  p {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .bar {
    width: 100%;
    height: 10px;
    background-color: #fff;
    border-radius: 30px;
    position: relative;
  }
`;

export const KioskNone = styled.div``;

type BarProps = {
  width: number;
};

export const BarValue = styled.div<BarProps>`
  width: ${(props) => props.width || '0'}%;
  height: 10px;
  background-color: #4575f6;
  border-radius: 30px;
  position: absolute;
`;

export const ChartBox = styled(Col)`
  flex: 1;
  position: relative;
`;

export const Chart = styled.div`
  width: calc(100% + 20px);
  height: 100%;
  border-radius: 10px;
  position: absolute;
  left: -10px;
`;

export const Split = styled(Col)`
  flex: 1;
  height: 100%;
  &:last-child {
    margin-left: 30px;
  }
`;

export const SplitContent = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #edf3f9;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
`;

export const Summary = styled(Col)<Props>`
  flex: 1;
  height: 100%;
  justify-content: space-between;
  background-color: ${(props) => props.bgc || '#edf3f9'};
  padding: 1vw;
  border-radius: 10px;
  &:last-child {
    margin-left: 30px;
  }
`;

export const Icon = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  background-color: #ffffff7f;
  border-radius: 50%;
  font-size: 25px;
`;

export const SummaryContent = styled(Col)``;

export const ImgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 90%;
  }
`;

export const FaQ = styled(Col)`
  margin-bottom: 30px;
  h4 {
    margin-bottom: 10px;
    &::before {
      font-family: 'FontAwesome';
      content: '\f00c';
      margin-right: 10px;
      color: #f8b400;
    }
  }
  p {
    font-size: 0.9rem;
    font-weight: 300;
  }
`;
