const colors = {
  orange: '#ff8c36',
  brand: '#538DCA',
  input: '#e8eff8',
  disabled: '#ecf0f1',
};

const common = {
  input: `
  border: none;
  line-height: 40px;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 300;
  font-size: 0.9rem;
  color: #191919;
  `,
};

const flex = {
  row: `
  display: flex;
  flex-direction: row;
  `,
  col: `
  display: flex;
  flex-direction: column;
  `,
};

const theme = {
  colors,
  common,
  flex,
};

export default theme;
