import { RefObject } from 'react';
import CSearch from '../CSearch';

type Props = {
  func: () => void;
  inputRef: RefObject<HTMLInputElement>;
};

const CSearchContainer = ({ func, inputRef }: Props) => {
  return <CSearch func={func} inputRef={inputRef} />;
};

export default CSearchContainer;
