import * as S from '@styles/PresenterStyle';
import TableContainer from '@components/Common/CTable/containers/TableContainer';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.type';
import { RefObject } from 'react';
import CSearchContainer from '@components/Common/CSearch/containers/CSearchContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
  columns: TableColumnTypes[];
  tableData: any;
  manage: (type: 'insert' | 'update') => void;
  deleteBelongData: () => Promise<void>;
  inputRef: RefObject<HTMLInputElement>;
  searchValue: string | null;
  searchTable: () => void;
  clearSearchValue: () => void;
};

const BelongManage = ({
  columns,
  tableData,
  manage,
  deleteBelongData,
  inputRef,
  searchValue,
  searchTable,
  clearSearchValue,
}: Props) => {
  return (
    <S.Presenter>
      <S.TitleBox>
        <h2>부서관리&nbsp;{`(${tableData.length})`}</h2>
        <S.Btns>
          <S.Btn onClick={() => manage('insert')} className='active'>
            부서 추가
          </S.Btn>
          <S.Btn onClick={() => manage('update')}>수정</S.Btn>
          <S.Btn onClick={deleteBelongData}>삭제</S.Btn>
        </S.Btns>
      </S.TitleBox>
      <S.FilterBox>
        <CSearchContainer func={searchTable} inputRef={inputRef} />
        <div className={searchValue ? 'filtered' : 'none'}>
          <S.SearchValue>
            <p>{searchValue}</p>
            <button type='button' onClick={clearSearchValue}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </S.SearchValue>
        </div>
      </S.FilterBox>
      <TableContainer
        columns={columns}
        tableData={tableData}
        searchValue={searchValue}
      />
    </S.Presenter>
  );
};

export default BelongManage;
