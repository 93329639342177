import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'redux';
import { ThemeProvider } from 'styled-components';
import rootReducer from '@stories/rootReducer';
import theme from './styles/theme';
import RootNavigation from '@routes/RootNavigation';
import useKiosk from '@hooks/useKiosk';
import { socket } from 'index';
import { useMemo } from 'react';
const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'development' ? composeWithDevTools() : undefined,
);

function App() {
  const { getKiosk } = useKiosk();
  const name = useMemo(() => getKiosk().name, [getKiosk]);
  const uid = useMemo(() => getKiosk().uid, [getKiosk]);
  socket.emit('join', {
    username: name,
    roomId: uid,
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <RootNavigation />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
