import styled from 'styled-components';

type Props = {
  borderNone: boolean;
};

export const TableBox = styled.div`
  flex: 1;
  ${({ theme }) => theme.flex.col}
  justify-content: space-between;
  border-radius: 5px;
  background-color: #fff;
`;

export const Table = styled.table<Props>`
  width: 100%;
  padding: 5px 10px;
  border-collapse: collapse;
  border-radius: 5px;

  thead th {
    color: #191919;
    text-align: center;
    line-height: 45px;
    font-weight: 600;
    background-color: #f5f7fa;
  }

  // 정렬
  .sorted {
    cursor: pointer;
    &::after {
      font-family: 'FontAwesome';
      content: '\f0dc';
      margin-left: 10px;
    }
  }
  .down:after {
    content: '\f0d8';
  }
  .up:after {
    content: '\f0d7';
  }

  tbody tr {
    line-height: 50px;
    color: #191919;
    font-size: 0.95rem;
    font-weight: 500;
    border-bottom: 1px solid #eee;
  }

  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }

  td:first-child,
  th:first-child {
    border-radius: 5px 0 0 5px;
  }
  td:last-child,
  th:last-child {
    border-radius: 0 5px 5px 0;
  }

  // 입고
  .status {
    width: fit-content;
    line-height: 25px;
    margin: 0 auto;
    padding: 0 7px;
    border-radius: 5px;
    background-color: #fdebea;
    color: #df4747;
    font-weight: 600;

    &::before {
      font-family: 'FontAwesome';
      content: '\f057';
      margin-right: 7px;
    }
  }

  .in {
    background-color: #edf3f9;
    color: #4575f6;

    &::before {
      content: '\f058';
    }
  }
`;

export const Pagination = styled.div`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;
  background-color: #f5f7fa;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;

  button {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin-left: 10px;
    background-color: #fff;
    border: 1px solid #ebedf0;
  }

  div {
    ${({ theme }) => theme.flex.row}
    align-items: center;
  }

  select {
    width: 100px;
    height: 40px;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    outline: none;
  }
`;

export const Index = styled.p`
  font-size: 0.9rem;
`;

export const Noting = styled.div`
  flex: 1;
  ${({ theme }) => theme.flex.col}
  align-items: center;
  justify-content: center;

  span {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
`;
