import * as S from '@styles/FormStyle';
import Select, { SingleValue } from 'react-select';
import { DeviceInfoTypes } from '@typedef/components/DeviceManage/device.info.types';
import { DeviceTypes } from '@typedef/components/DeviceManage/device.types';
import { DeviceInputsTypes } from '@typedef/components/DeviceManage/components/device.inputs.types';

type Props = {
  type: string;
  inputs: DeviceInputsTypes;
  setInputs: React.Dispatch<React.SetStateAction<DeviceInputsTypes>>;
  deviceOptions: DeviceTypes[];
  onChangeModelName: (option: SingleValue<DeviceTypes> | null) => void;
  colorOptions: DeviceInfoTypes[];

  onChangeSerailNumber: (value: string) => void;
  save: () => Promise<void>;
};

const DeviceInfo = ({
  type,
  inputs,
  setInputs,
  deviceOptions,
  onChangeModelName,
  colorOptions,
  onChangeSerailNumber,
  save,
}: Props) => {
  return (
    <S.Page>
      <S.TitleBox>
        <h2>단말 {type}</h2>
        <S.Btn type='button' onClick={save} className='active'>
          저장
        </S.Btn>
      </S.TitleBox>
      <S.Form>
        <h3>단말 정보</h3>
        <S.Col>
          <S.InputBox>
            <p>
              모델명<span>*</span>
            </p>
            <Select<DeviceTypes>
              options={deviceOptions}
              getOptionValue={(data: DeviceTypes) => data.idx}
              getOptionLabel={(data: DeviceTypes) => data.pet_name}
              value={deviceOptions.filter(
                (device) => device.idx === inputs.device_idx,
              )}
              key={inputs.device_idx}
              onChange={onChangeModelName}
              placeholder='모델명 검색'
              isClearable={true}
              className='select'
            />
          </S.InputBox>
          <S.InputBox>
            <p>
              색상<span>*</span>
            </p>
            <Select<DeviceInfoTypes>
              options={colorOptions}
              getOptionValue={(info: DeviceInfoTypes) => info.idx}
              getOptionLabel={(info: DeviceInfoTypes) => info.color.name}
              value={colorOptions.filter(
                (info) => info.idx === inputs.device_info_idx,
              )}
              key={inputs.device_info_idx}
              onChange={(option: SingleValue<DeviceInfoTypes>) => {
                if (option) {
                  setInputs((inputs) => ({
                    ...inputs,
                    device_info_idx: option.idx,
                  }));
                }
              }}
              placeholder={
                inputs.device_idx ? '색상 검색' : '모델을 먼저 선택해 주세요.'
              }
              isDisabled={!inputs.device_idx}
              className='select'
            />
          </S.InputBox>
          <S.InputBox>
            <p>
              일련번호<span>*</span>
            </p>
            <S.Input
              name='serail_number'
              defaultValue={inputs.serial_number}
              onChange={(e) => onChangeSerailNumber(e.target.value)}
              placeholder='일련번호'
            />
          </S.InputBox>
        </S.Col>
      </S.Form>
    </S.Page>
  );
};

export default DeviceInfo;
