import AdminManage from '../AdminManage';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import useToken from '@hooks/useToken';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.type';
import CheckBoxContainer from '@components/Common/CInput/containers/CheckBoxContainer';
import { NoColumn } from '@components/Common/CTable/components/IdxColumn';
import { apiRoute, requestSecureDelete, requestSecureGet } from '@libs/api';
import { AdminManageDataTypes } from '@typedef/components/AdminManage/adminManage.data.types';
import { useNavigate } from 'react-router-dom';

const AdminManageContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();
  const [tableData, setTableData] = useState<AdminManageDataTypes[]>([]);
  const [selectRows, setSelectRows] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // 관리자 등록 / 수정
  const manage = useCallback(
    (type: 'insert' | 'update', idx?: number) => {
      let url = `/admin-info?type=${type}`;
      if (type === 'update') {
        if (selectRows.length > 1) {
          alert('수정할 정보를 하나만 선택해 주세요.');
          return;
        } else if (selectRows.length === 0) {
          alert('수정할 정보를 선택해 주세요.');
          return;
        }
        url += `&adminId=${selectRows[0]}`;
      }
      navigate(url);
    },
    [navigate, selectRows],
  );

  // 데이터 상세 조회
  // const detailAdmin = useCallback(
  //   (idx: number) => {
  //     const filter = tableData.filter((data) => data.idx === idx);
  //     setDetailData(filter[0]);
  //   },
  //   [tableData],
  // );

  // 데이터 삭제
  const deleteAdminData = useCallback(async () => {
    if (selectRows.length === 0) {
      alert('삭제할 데이터를 선택해 주세요.');
      return;
    }
    if (
      window.confirm(
        '해당 관리자와 관련된 모든 정보가 사라집니다.\n삭제하시겠습니까',
      )
    ) {
      await Promise.all(
        selectRows.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.admin.deleteAdmin + idx,
                {},
                getAccessToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                } else {
                  reject(`http request failed with` + data.config.status);
                }
              });
            }),
        ),
      )
        .then(() => {
          alert('성공적으로 삭제가 완료되었습니다.');
          window.location.reload();
        })
        .catch(() => {
          alert('삭제 실패');
        });
    }
  }, [getAccessToken, selectRows]);

  const onChangeCheckBox = useCallback(
    (idx: number) => {
      if (selectRows.includes(idx)) {
        const filter = selectRows.filter((row) => row !== idx);
        setSelectRows(filter);
        return;
      }
      setSelectRows((row) => [...row, idx]);
    },
    [selectRows],
  );

  const columns: TableColumnTypes[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'checkbox',
        disableSortBy: true,
        width: 50,
        Cell: ({ row }: { row: any }) => {
          return (
            <CheckBoxContainer
              id={row.original.idx}
              defaultChecked={selectRows.includes(row.original.idx)}
              onChangeFunc={onChangeCheckBox}
            />
          );
        },
      },
      NoColumn('idx'),
      {
        Header: '부서명',
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: '대표전화',
        accessor: 'phone',
        disableSortBy: true,
      },
      {
        Header: '아이디',
        accessor: 'manager_id',
        disableSortBy: true,
      },
      {
        Header: '담당자',
        accessor: 'manager_name',
        disableSortBy: true,
      },
      {
        Header: '담당자 연락처',
        accessor: 'manager_phone',
        disableSortBy: true,
      },
    ],
    [onChangeCheckBox, selectRows],
  );

  // 테이블 검색
  const searchTable = useCallback(() => {
    if (inputRef.current) {
      setSearchValue(inputRef.current.value);
    }
  }, []);

  // 테이블 검색내역 삭제
  const clearSearchValue = useCallback(() => {
    const { current } = inputRef;
    if (current) {
      current.value = '';
    }
    setSearchValue(null);
  }, []);

  const getTableData = useCallback(async () => {
    const { config, data } = await requestSecureGet<AdminManageDataTypes[]>(
      apiRoute.admin.getAdmins,
      {},
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400)
      setTableData(data.reverse());
  }, [getAccessToken]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  return (
    <AdminManage
      columns={columns}
      tableData={tableData}
      manage={manage}
      deleteAdminData={deleteAdminData}
      searchTable={searchTable}
      searchValue={searchValue}
      inputRef={inputRef}
      clearSearchValue={clearSearchValue}
    />
  );
};

export default AdminManageContainer;
