import * as S from './styles/TableStyle';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from 'react-table';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.type';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
} from '@fortawesome/free-solid-svg-icons';
type Props = {
  columns: TableColumnTypes[];
  tableData: any;
  filter?: any;
  searchValue?: string | null;
};

function Table({ columns, tableData, searchValue }: Props) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  // 검색
  useEffect(() => {
    setGlobalFilter(searchValue);
  }, [searchValue, setGlobalFilter]);

  return (
    <S.TableBox>
      <S.Table {...getTableProps()} borderNone={tableData.length === 0}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ width: column.width }}
                  className={
                    column.disableSortBy
                      ? ''
                      : column.isSorted
                      ? column.isSortedDesc
                        ? 'sorted down'
                        : 'sorted up'
                      : 'sorted'
                  }>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </S.Table>
      {tableData.length !== 0 ? (
        <S.Pagination>
          <S.Index>
            Page&nbsp;
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </S.Index>
          <div>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <FontAwesomeIcon icon={faAnglesLeft} />
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>

            <button onClick={() => nextPage()} disabled={!canNextPage}>
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}>
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
          </div>
          {/* <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}>
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select> */}
        </S.Pagination>
      ) : (
        <S.Noting>
          <h4>등록된 데이터가 없습니다.</h4>
        </S.Noting>
      )}
    </S.TableBox>
  );
}

export default Table;
