import { useState } from 'react';
import * as S from './styles/LoginStyle';
import image from '../../assets/image';

type Props = {
  onChangeInputs: (event: { target: HTMLInputElement }) => void;
  adminLogin: () => void;
  userLogin: () => void;
};

const Login = ({ onChangeInputs, adminLogin, userLogin }: Props) => {
  const tabs = ['관리자', '사용자'];
  const [isTab, setIsTab] = useState(tabs[0]);
  const onClickTab = (tab: string) => {
    setIsTab(tab);
  };
  return (
    <S.Login>
      <img src={image.idmLogo} alt='IDM 로고' />
      <div className='content'>
        <S.Tabs>
          <S.Tab
            onClick={() => onClickTab('관리자')}
            className={isTab === '관리자' ? '' : 'onUser'}>
            관리자
          </S.Tab>
          <S.Tab
            onClick={() => onClickTab('사용자')}
            className={isTab === '사용자' ? '' : 'onAdmin'}>
            사용자
          </S.Tab>
        </S.Tabs>
        {isTab === '관리자' ? (
          <S.Form>
            <input
              name='adminId'
              placeholder='아이디'
              onChange={onChangeInputs}
              autoFocus
            />
            <input
              name='password'
              type='password'
              placeholder='비밀번호'
              onChange={onChangeInputs}
              onKeyPress={(e) => {
                if (e.key === 'Enter') adminLogin();
              }}
            />
            <S.LoginBtn onClick={adminLogin}>로그인</S.LoginBtn>
          </S.Form>
        ) : (
          <S.Form>
            <p>
              관리자 페이지 &gt; 단말보관함관리 <br />
              &gt;&nbsp;<span>고유번호</span>를 입력해 주세요.
            </p>
            <input
              name='userId'
              onChange={onChangeInputs}
              placeholder='단말보관함 번호'
            />
            <S.LoginBtn onClick={userLogin}>로그인</S.LoginBtn>
          </S.Form>
        )}
      </div>
    </S.Login>
  );
};

export default Login;
