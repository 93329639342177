import DeviceInfo from '../components/DeviceInfo';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  apiRoute,
  requestSecureGet,
  requestSecurePost,
  requestSecurePatch,
} from '@libs/api';
import useToken from '@hooks/useToken';
import { DeviceTypes } from '@typedef/components/DeviceManage/device.types';
import { DeviceInfoTypes } from '@typedef/components/DeviceManage/device.info.types';
import { DeviceInputsTypes } from '@typedef/components/DeviceManage/components/device.inputs.types';
import { SingleValue } from 'react-select';
import { ShopDeviceTypes } from '@typedef/components/DeviceManage/shop.device.types';

const DeviceInfoContainer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getAccessToken } = useToken();
  const [type, setType] = useState<string | null>(null);
  const [inputs, setInputs] = useState<DeviceInputsTypes>({
    shop_device_idx: '',
    device_info_idx: '',
    device_idx: '',
    serial_number: '',
  });
  const [deviceOptions, setDeviceOptions] = useState<DeviceTypes[]>([]);
  const [colorOptions, setColorOptions] = useState<DeviceInfoTypes[]>([]);

  // 색상 선택에서 옵션으로 사용할 색상 리스트 조회
  const getColorOptions = useCallback(
    async (name: string | undefined) => {
      const { config, data } = await requestSecureGet<DeviceInfoTypes[]>(
        apiRoute.device.getColorByModelName + name,
        {},
        getAccessToken()!,
      );
      if (config.status >= 200 && config.status < 400) {
        setColorOptions(data);
      }
    },
    [getAccessToken],
  );

  // 모델명 선택 함수
  const onChangeModelName = useCallback(
    (option: SingleValue<DeviceTypes> | null) => {
      if (option) {
        // 모델명으로 색상 조회 함수 호출
        getColorOptions(option.model_name);
        inputs.device_idx = option.idx;
      } else {
        inputs.device_idx = '';
      }
    },
    [getColorOptions, inputs],
  );

  // 일련번호 입력
  const onChangeSerailNumber = useCallback(
    (value: string) => {
      inputs.serial_number = value;
      setInputs(inputs);
    },
    [inputs],
  );

  const allInputs = useCallback(() => {
    let message = '';
    if (!!!inputs.device_idx) message = '모델명을 선택해';
    else if (!!!inputs.device_info_idx) message = '색상을 선택해';
    else if (!!!inputs.serial_number) message = '일련번호를 입력해';
    return message;
  }, [inputs]);

  // 단말 등록
  const insert = useCallback(async () => {
    const message = allInputs();
    if (!!message) {
      alert(`${message} 주세요.`);
      return;
    }

    const postData = {
      device_info_idx: inputs.device_info_idx,
      serial_number: inputs.serial_number,
    };

    const { config } = await requestSecurePost<DeviceInputsTypes>(
      apiRoute.shopDevice.postShopDevice,
      {},
      { devices: [postData] },
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 등록이 완료되었습니다.');
      navigate('/device-manage');
    }
  }, [allInputs, getAccessToken, inputs, navigate]);

  // 단말 수정
  const update = useCallback(async () => {
    const message = allInputs();
    if (!!message) {
      alert(`${message} 주세요.`);
      return;
    }

    const patchData = {
      idx: inputs.shop_device_idx,
      device_info_idx: inputs.device_info_idx,
      serial_number: inputs.serial_number,
      status_idx: 2,
    };

    const { config } = await requestSecurePatch<DeviceInputsTypes>(
      apiRoute.shopDevice.patchShopDevice,
      {},
      patchData,
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 수정이 완료되었습니다.');
      navigate('/device-manage');
    }
  }, [allInputs, getAccessToken, inputs, navigate]);

  // 모델명 선택에서 옵션으로 사용할 단말 리스트 조회
  const getDeviceOptions = useCallback(async () => {
    const { config, data } = await requestSecureGet<DeviceTypes[]>(
      apiRoute.device.getDevices,
      {},
      getAccessToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      // 오름차순 정렬, 같은 이름(pet_name)을 가진 단말들은 하나만 보이게
      const filtered = data
        .sort(function (a, b) {
          return a.pet_name < b.pet_name ? -1 : a.pet_name > b.pet_name ? 1 : 0;
        })
        .filter((data, idx, arr) => {
          return (
            arr.findIndex((item) => item.pet_name === data.pet_name) === idx
          );
        });
      setDeviceOptions(filtered);
    }
  }, [getAccessToken]);

  // 타입이 수정일 때 상세조회
  const getShopDeviceData = useCallback(
    async (idx: string) => {
      const { config, data } = await requestSecureGet<ShopDeviceTypes>(
        apiRoute.shopDevice.getShopDevice + idx,
        {},
        getAccessToken()!,
      );
      if (config.status >= 200 && config.status < 400) {
        setInputs({
          ...inputs,
          shop_device_idx: data.idx,
          device_info_idx: data.device_info.idx,
          device_idx: data.device_info.device.idx,
          serial_number: data.serial_number,
        });
        getColorOptions(data.device_info?.device.model_name);
      }
    },
    [getAccessToken, getColorOptions, inputs],
  );

  const getQuries = useCallback(() => {
    const type = searchParams.get('type');
    const deviceId = searchParams.get('deviceId');
    if (type) {
      setType(type);
    }
    if (deviceId) {
      getShopDeviceData(deviceId);
    }
  }, [getShopDeviceData, searchParams]);

  useEffect(() => {
    getDeviceOptions();
    getQuries();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(
    'inputs :',
    colorOptions.filter((info) => info.idx === inputs.device_info_idx),
  );

  return (
    <DeviceInfo
      type={type === 'insert' ? '추가' : '수정'}
      inputs={inputs}
      setInputs={setInputs}
      deviceOptions={deviceOptions}
      onChangeModelName={onChangeModelName}
      colorOptions={colorOptions}
      onChangeSerailNumber={onChangeSerailNumber}
      save={type === 'insert' ? insert : update}
    />
  );
};

export default DeviceInfoContainer;
