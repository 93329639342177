import * as S from '@styles/ModalStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ShopDeviceTypes } from '@typedef/components/DeviceManage/shop.device.types';

type Props = {
  onClickCloseModal: (e: React.MouseEvent<HTMLDivElement>) => void;
  onChangeSerialNum: (value: string) => void;
  devices: ShopDeviceTypes[] | null;
  onClickDevice: (device: ShopDeviceTypes) => void;
  onClickCancelDevice: () => void;
  selectedDevice: ShopDeviceTypes | null;
  onClickAddBtn: () => void;
};

const SelectUserDevice = ({
  onClickCloseModal,
  onChangeSerialNum,
  devices,
  onClickDevice,
  onClickCancelDevice,
  selectedDevice,
  onClickAddBtn,
}: Props) => {
  return (
    <S.ModalBackGround id='backGround' onClick={onClickCloseModal}>
      <S.Modal>
        <S.ModalTitleBox>
          <h3>사용자 단말 추가</h3>
        </S.ModalTitleBox>
        <S.ModalContent>
          {selectedDevice ? (
            <S.Selected>
              <S.Col>
                <div className='td'>
                  <p>단말명:</p>
                  <strong>{selectedDevice.device_info.device.pet_name}</strong>
                </div>
                <div className='td'>
                  <p>단말 색상:</p>
                  <strong>{selectedDevice.device_info.color.name}</strong>
                </div>
                <div className='td'>
                  <p>단말 일련번호:</p>
                  <strong>{selectedDevice.serial_number}</strong>
                </div>
              </S.Col>
              <button type='button' onClick={onClickCancelDevice}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </S.Selected>
          ) : (
            <>
              <S.Search
                placeholder='일련번호로 단말을 검색해 주세요.'
                onChange={(e) => onChangeSerialNum(e.target.value)}
              />
              <S.Table>
                <S.Tbody>
                  {devices &&
                    devices.map((device) => (
                      <S.Tr
                        key={device.idx}
                        onClick={() => onClickDevice(device)}>
                        <strong>{device.serial_number}</strong>
                        <S.Row>
                          <div>{device.device_info.device.pet_name}</div>
                          <div>&nbsp;({device.device_info.color.name})</div>
                        </S.Row>
                      </S.Tr>
                    ))}
                </S.Tbody>
              </S.Table>
            </>
          )}
        </S.ModalContent>
        <S.ModalBtns>
          <S.ModalBtn
            type='button'
            disabled={!selectedDevice}
            onClick={onClickAddBtn}>
            추가
          </S.ModalBtn>
        </S.ModalBtns>
      </S.Modal>
    </S.ModalBackGround>
  );
};

export default SelectUserDevice;
