import { TestActionType } from './actions';
import { TEST_ACTION } from './modules/actionTypes';

export type TestState = {
  message: 'hello world';
};

const initialState: TestState = { message: 'hello world' };

const testReducer = (
  prev: TestState | undefined = initialState,
  action: TestActionType,
): TestState => {
  switch (action.type) {
    case TEST_ACTION:
      return { message: 'hello world' };
    default:
      return prev;
  }
};

export default testReducer;
