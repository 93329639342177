import styled from 'styled-components';

export const Login = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  img {
    width: 130px;
    margin-bottom: 30px;
  }

  .content {
    width: 400px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }

  // tabOn 활성화시
  .onUser {
    border-radius: 0 0 5px 0;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
  }
  .onAdmin {
    border-radius: 0 0 0 5px;
    border-left: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
  }
`;

export const Tabs = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 50px;
`;

export const Tab = styled.div`
  display: table-cell;
  position: relative;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  height: 230px;
  box-sizing: border-box;
  padding: 20px;
  justify-content: space-between;
  input {
    line-height: 50px;
    padding-left: 20px;
    border: none;
    background-color: #e9eff4;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 1rem;
    &::placeholder {
      font-weight: 200;
      color: #777;
    }
  }

  p {
    text-align: center;
    font-weight: 300;
  }
  span {
    font-weight: 500;
  }
`;

export const LoginBtn = styled.div`
  text-align: center;
  border-radius: 5px;
  line-height: 50px;
  border: none;
  background-color: #5f8fc8;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
`;
