import styled from 'styled-components';

export const Header = styled.div`
  min-height: 60px;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 1px solid #eee;
  .logo {
    height: 30px;
    margin-top: 5px;
  }
`;

export const Profile = styled.div`
  ${({ theme }) => theme.flex.row}
  font-size: 0.9rem;
  position: relative;
  strong {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ProfileMenu = styled.div`
  width: 150px;
  position: absolute;
  top: 20px;
  right: 0;
  padding-top: 10px;
  z-index: 10;
`;

export const Menu = styled.div`
  ${({ theme }) => theme.flex.col}
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  padding: 10px;

  .user {
    padding: 10px;
    font-size: 0.9rem;
    border-bottom: 1px solid #eee;
    font-weight: 500;
  }

  button {
    padding: 10px;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: left;
  }
`;
