import * as S from '../styles/CCheckBoxStyle';
import { CheckBoxTypes } from '@typedef/components/Common/Input/checkbox/checkbox.type';

const CheckBox = ({ id, defaultChecked, onChangeFunc }: CheckBoxTypes) => {
  return (
    <S.CheckBox>
      <input
        type='checkbox'
        id={id}
        defaultChecked={defaultChecked}
        onChange={(e) => onChangeFunc(+e.target.id)}
      />
    </S.CheckBox>
  );
};

export default CheckBox;
