import ProfileMenu from '../components/ProfileMenu';
import { useCallback, useMemo } from 'react';
import useToken from '@hooks/useToken';
import useUser from '@hooks/useUser';

type Props = {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileMenuContainer = ({ setShowMenu }: Props) => {
  const {
    getAccessToken,
    getRefreshToken,
    clearAccessToken,
    clearRefreshToken,
  } = useToken();
  const { getUser, clearUser } = useUser();
  const userName = useMemo(() => getUser().name, [getUser]);

  // 로그아웃
  const logout = useCallback(() => {
    const accessToken = getAccessToken()!;
    const refreshToken = getRefreshToken()!;
    clearAccessToken(accessToken);
    clearRefreshToken(refreshToken);
    clearUser();
    window.location.reload();
  }, [
    clearAccessToken,
    clearRefreshToken,
    clearUser,
    getAccessToken,
    getRefreshToken,
  ]);

  return (
    <ProfileMenu
      setShowMenu={setShowMenu}
      userName={userName}
      logout={logout}
    />
  );
};

export default ProfileMenuContainer;
