import UserMain from '../UserMain';
import { useState, useEffect, useCallback, useMemo } from 'react';
import useKiosk from '@hooks/useKiosk';
import { socket } from 'index';
import { apiRoute, requestGet } from '@libs/api';

import { UserTypes } from '@typedef/components/UserManage/user.types';

const UserMainContainer = () => {
  const { getKiosk, clearKiosk } = useKiosk();
  const name = useMemo(() => getKiosk().name, [getKiosk]);
  const uid = useMemo(() => getKiosk().uid, [getKiosk]);
  const [kioskUsers, setKioskUsers] = useState<UserTypes[] | null>(null);

  const getKioskUsersData = useCallback(async () => {
    const { config, data } = await requestGet<UserTypes[]>(
      apiRoute.user.getKioskUsers + uid,
      {},
    );
    if (config.status >= 200 && config.status < 400) {
      setKioskUsers(data);
    }
  }, [uid]);

  const getSocketUsers = useCallback(() => {
    socket.on('joinGroup', (username) => {
      console.log(username + '으로 조인됨.');
    });
    socket.on('apiresponse', (users) => {
      console.log('실시간 데이터 :', users);
      setKioskUsers(users);
    });
  }, []);

  const logout = useCallback(() => {
    clearKiosk();
    window.location.reload();
  }, [clearKiosk]);

  useEffect(() => {
    getKioskUsersData();
    getSocketUsers();
  }, [getKioskUsersData, getSocketUsers]);

  return <UserMain name={name} kioskUsers={kioskUsers} logout={logout} />;
};

export default UserMainContainer;
