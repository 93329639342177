import AdminMainChart from '../components/AdminMainChart';

const data = [
  {
    id: '보관중',
    data: [
      {
        x: '5/28',
        y: 30,
      },
      {
        x: '5/29',
        y: 230,
      },
      {
        x: '5/30',
        y: 247,
      },
      {
        x: '5/31',
        y: 238,
      },
      {
        x: '6/1',
        y: 245,
      },
      {
        x: '6/2',
        y: 243,
      },
      {
        x: '6/3',
        y: 247,
      },
    ],
  },
  {
    id: '사용중',
    data: [
      {
        x: '5/28',
        y: 200,
      },
      {
        x: '5/29',
        y: 20,
      },
      {
        x: '5/30',
        y: 8,
      },
      {
        x: '5/31',
        y: 18,
      },
      {
        x: '6/1',
        y: 28,
      },
      {
        x: '6/2',
        y: 12,
      },
      {
        x: '6/3',
        y: 18,
      },
    ],
  },
];

const AdminMainChartContainer = () => {
  return <AdminMainChart data={data} />;
};

export default AdminMainChartContainer;
