import KioskInfo from "../components/KioskInfo";
import { useState, useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from "@libs/api";
import useToken from "@hooks/useToken";
import { KioskInputsDataTypes } from "@typedef/components/KioskManage/components/kioskInputs.data.types";
import { UserTypes } from "@typedef/components/UserManage/user.types";

const KioskInfoContainer = () => {
  const { getAccessToken } = useToken();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [type, setType] = useState<string | null>(null);
  // 단말보관함 정보
  const [inputs, setInputs] = useState<KioskInputsDataTypes>({
    name: "",
    max: "",
    memo: "",
  });
  // 단말보관함 사용자 정보
  const [userInputs, setUserInputs] = useState<UserTypes[] | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  // onChange(inputs)
  const onChangeInputs = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  }, []);

  // 입력 여부 확인
  const allInputs = useCallback(() => {
    let message = "";
    if (!!!inputs.name) message = "보관함명을";
    else if (!!!inputs.max) message = "최대 보관개수를";
    else if (isNaN(+inputs.max)) message = "최대 보관개수는 숫자만";
    return message;
  }, [inputs]);

  // 단말보관함 등록
  const insert = useCallback(async () => {
    const message = allInputs();
    if (!!message) {
      alert(`${message} 입력해 주세요.`);
      return;
    }

    const postData = {
      ...inputs,
    };

    const { config, data } = await requestSecurePost<KioskInputsDataTypes>(
      apiRoute.kiosk.postKiosk,
      {},
      postData,
      getAccessToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      // 단말 보관함 사용자 추가 시 사용자 api 수정 요청
      if (userInputs) {
        const userIdxes = userInputs.map((user) => {
          return {
            idx: user.idx,
          };
        });
        const patchData = {
          idxes: userIdxes,
          kiosk_idx: data.idx,
        };
        const { config } = await requestSecurePatch<any>(
          apiRoute.user.patchUserKioskIdx,
          {},
          patchData,
          getAccessToken()!
        );
        if (config.status >= 200 && config.status < 400) {
          alert("성공적으로 등록이 완료되었습니다.");
          navigate("/kiosk-manage");
          return;
        }
      }
      alert("성공적으로 등록이 완료되었습니다.");
      navigate("/kiosk-manage");
    }
  }, [allInputs, getAccessToken, inputs, userInputs, navigate]);

  // 단말보관함 수정
  const update = useCallback(async () => {
    const message = allInputs();
    if (!!message) {
      alert(`${message} 입력해 주세요.`);
      return;
    }

    const patchData = {
      ...inputs,
    };

    const { config, data } = await requestSecurePatch<KioskInputsDataTypes>(
      apiRoute.kiosk.patchtKiosk,
      {},
      patchData,
      getAccessToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      // 단말 보관함 사용자 추가 시 사용자 api 수정 요청
      if (userInputs) {
        const userIdxes = userInputs.map((user) => {
          return {
            idx: user.idx,
          };
        });
        const patchData = {
          idxes: userIdxes,
          kiosk_idx: data.idx,
        };
        const { config } = await requestSecurePatch<KioskInputsDataTypes>(
          apiRoute.user.patchUserKioskIdx,
          {},
          patchData,
          getAccessToken()!
        );
        if (config.status >= 200 && config.status < 400) {
          alert("성공적으로 수정이 완료되었습니다.");
          navigate("/kiosk-manage");
          return;
        }
      }
      alert("성공적으로 수정이 완료되었습니다.");
      navigate("/kiosk-manage");
    }
  }, [allInputs, getAccessToken, inputs, userInputs, navigate]);

  // 타입이 수정일 때 상세조회
  const getKioskData = useCallback(
    async (idx: string) => {
      const { config, data } = await requestSecureGet<KioskInputsDataTypes>(
        apiRoute.kiosk.getKiosk + idx,
        {},
        getAccessToken()!
      );
      if (config.status >= 200 && config.status < 400) {
        const { users, kiosk } = data;
        if (kiosk) {
          setInputs(kiosk);
        }
        if (users) {
          setUserInputs(users);
        }
      }
    },
    [getAccessToken]
  );

  const getQuries = useCallback(() => {
    const type = searchParams.get("type");
    const kioskId = searchParams.get("kioskId");
    if (type) {
      setType(type);
    }
    if (kioskId) {
      getKioskData(kioskId);
    }
  }, [getKioskData, searchParams]);

  useEffect(() => {
    getQuries();
  }, [getQuries]);

  return (
    <KioskInfo
      type={type === "insert" ? "추가" : "수정"}
      inputs={inputs}
      userInputs={userInputs}
      onChangeInputs={onChangeInputs}
      showModal={showModal}
      setShowModal={setShowModal}
      setUserInputs={setUserInputs}
      save={type === "insert" ? insert : update}
    />
  );
};

export default KioskInfoContainer;
