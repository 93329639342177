const IdxColumn = (row) => {
  return <>{row.data.length - row.row.index}</>;
};

export default IdxColumn;

export const NoColumn = (idxKey) => {
  return {
    Header: 'No',
    accessor: (originalRow) => {
      return originalRow[idxKey];
    },
    width: 65,
    Cell: IdxColumn,
  };
};
