import AdminMain from "../AdminMain";
import { useState, useEffect, useCallback, useRef } from "react";
import { apiRoute, requestSecureGet } from "@libs/api";
import useToken from "@hooks/useToken";
import { ShopDeviceTypes } from "@typedef/components/DeviceManage/shop.device.types";
import { AdminMainKioskTypes } from "@typedef/components/AdminMain/admin.main.kiosk.types";
import { KioskDataTypes } from "@typedef/components/KioskManage/kiosk.data.types";
import useUser from "@hooks/useUser";

const AdminMainContainer = () => {
  const { getAccessToken } = useToken();
  const { getUser } = useUser();
  const [kioskData, setKioskData] = useState<AdminMainKioskTypes[] | null>(
    null
  );
  const [putDevices, setPutDevice] = useState<ShopDeviceTypes[] | null>(null);
  const [releaseDevices, setReleaseDevice] = useState<ShopDeviceTypes[] | null>(
    null
  );
  const sliderRef = useRef(null);

  // slick 버튼 이벤트
  const onClickSlickPrev = useCallback(
    (ref: any) => () => ref.current.slickPrev(),
    []
  );
  const onClickSlickNext = useCallback(
    (ref: any) => () => ref.current.slickNext(),
    []
  );

  const getDeviceData = useCallback(async () => {
    const { config, data } = await requestSecureGet<ShopDeviceTypes[]>(
      apiRoute.shopDevice.getShopDevices,
      {},
      getAccessToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setPutDevice(data.filter((device) => device.status?.name === "입고"));
      setReleaseDevice(data.filter((device) => device.status?.name === "출고"));
    }
  }, [getAccessToken]);

  const getKioskData = useCallback(async () => {
    const { config, data } = await requestSecureGet<KioskDataTypes[]>(
      apiRoute.kiosk.getKiosks + `/list/${getUser().shop_idx}`,
      {},
      getAccessToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      const settingData = data.map((data) => ({
        ...data,
        user: data.users ? data.users.length : 0,
        user_put: data.users
          ? data.users.filter(
              (user) => user?.belong_device?.status.name === "입고"
            ).length
          : 0,
      }));
      setKioskData(settingData);
    }
  }, [getAccessToken, getUser]);

  useEffect(() => {
    getKioskData();
    getDeviceData();
  }, [getKioskData, getDeviceData]);

  return (
    <AdminMain
      sliderRef={sliderRef}
      onClickSlickPrev={onClickSlickPrev}
      onClickSlickNext={onClickSlickNext}
      kioskData={kioskData}
      putDevices={putDevices}
      releaseDevices={releaseDevices}
    />
  );
};

export default AdminMainContainer;
