import * as S from './styles/CSearchStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { RefObject } from 'react';

type Props = {
  func: () => void;
  inputRef: RefObject<HTMLInputElement>;
};

const CSearch = ({ func, inputRef }: Props) => {
  return (
    <S.CSearch>
      <input
        id='searchInput'
        placeholder='검색어를 입력하세요'
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            func();
          }
        }}
        ref={inputRef}
      />
      <button type='button' onClick={func}>
        <FontAwesomeIcon icon={faSearch} />
      </button>
    </S.CSearch>
  );
};

export default CSearch;
