import * as S from '@styles/ModalStyle';

type Props = {
  onClickCloseModal: (e: React.MouseEvent<HTMLDivElement>) => void;
  onChangePassword: (value: string) => void;
  pwMsg: string | null;
  onChangePasswordConfirm: (value: string) => void;
  pwConfirmMessage: string | null;
  pwConfirm: string | null;
  save: () => Promise<void>;
};

const ChangePassword = ({
  onClickCloseModal,
  onChangePassword,
  pwMsg,
  onChangePasswordConfirm,
  pwConfirmMessage,
  pwConfirm,
  save,
}: Props) => {
  return (
    <S.ModalBackGround id='backGround' onClick={onClickCloseModal}>
      <S.Modal>
        <S.ModalTitleBox>
          <h3>비밀번호 변경</h3>
        </S.ModalTitleBox>
        <S.ModalContent>
          <S.InputBox>
            <S.Input
              type='password'
              name='pw'
              onBlur={(e) => onChangePassword(e.target.value)}
              placeholder='새 비밀번호'
              autoFocus
            />
            {pwMsg && <span className='msg'>{pwMsg}</span>}
          </S.InputBox>
          <S.InputBox>
            <S.Input
              type='password'
              name='pw_confirm'
              onBlur={(e) => onChangePasswordConfirm(e.target.value)}
              placeholder='새 비밀번호 확인'
            />
            {pwConfirmMessage && (
              <span className='msg'>{pwConfirmMessage}</span>
            )}
          </S.InputBox>
        </S.ModalContent>
        <S.ModalBtns>
          <S.ModalBtn type='button' disabled={!pwConfirm} onClick={save}>
            비밀번호 변경
          </S.ModalBtn>
        </S.ModalBtns>
      </S.Modal>
    </S.ModalBackGround>
  );
};

export default ChangePassword;
